import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, message } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../redux/store';
import { createUser } from '../../redux/users/users.actions';

type UserAddModalProps = {};
export const UserAddModal: React.FC<UserAddModalProps> = ({}) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const onCreateUser = () => {
        setShowModal(true);
    };

    const onCancel = () => {
        form.resetFields();
        setShowModal(false);
    };

    return (
        <>
            <Button type={'text'} icon={<PlusOutlined rev={undefined} />} onClick={onCreateUser}>
                Создать пользователя
            </Button>
            <Modal centered={true} open={showModal} onCancel={onCancel} title={'Cоздание пользователя'} footer={false}>
                <Form
                    onFinish={values => {
                        const correct = values.password === values.confirmPassword;

                        if (correct) {
                            dispatch(createUser({ login: values.login, password: values.password }));
                            onCancel();
                        } else message.error('Пароли не совпадают');
                    }}
                    form={form}
                    layout='vertical'
                >
                    <Form.Item name={'login'} label={'Логин'}>
                        <Input placeholder={'Логин'} size='large' />
                    </Form.Item>
                    <Form.Item name={'password'} label={'Пароль'}>
                        <Input placeholder={'Пароль'} type={'password'} size='large' />
                    </Form.Item>
                    <Form.Item name={'confirmPassword'} label={'Повторите пароль'}>
                        <Input placeholder={'Повторите пароль'} type={'password'} size='large' />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type={'primary'} htmlType='submit'>
                                Создать
                            </Button>
                            <Button onClick={onCancel}>Отмена</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
