import MDEditor from '@uiw/react-md-editor'
import { Col, Row, UploadProps, message } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload'
import { AxiosRequestConfig } from 'axios'
import { UploadProgressEvent } from 'rc-upload/lib/interface'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { fileApi } from '../../api/backend-wrapper'
import { fetchFiles } from '../../redux/files/files.actions'
import { RootState, useAppDispatch } from '../../redux/store'
import { Chunks } from '../../types/СhunkUpload.types'
import { endUpload, startUpload, uploadChunk } from '../../utils/chunkUpload'
import { FilesModals } from '../UI/Modals/FilesModals/FilesModals'
import UploadDragger from '../UI/Uploads/Dragger/UploadDragger'
import FilesTable from './FilesTable'
import { setFile } from '../../redux/files/filesSlice'
import { useSelector } from 'react-redux'
import WarningModal from '../UI/Modals/WarningModal/WarningModal'

export const rootDir = '00000000-0000-0000-0000-000000000000'

let continueUploadPromise: (shouldContinue: boolean) => void

export const FilesLayout: React.FC = () => {
	const navigate = useNavigate()
	const { id, mdId } = useParams()
	const dispatch = useAppDispatch()
	const { files } = useSelector((root: RootState) => root.files)

	const [isDragging, setIsDragging] = React.useState<boolean>(false)
	const [uploadedChunks, setUploadedChunks] = React.useState<Chunks>({})
	const [fileList, setFileList] = React.useState<UploadProps['fileList']>([])
	const [showMdPreview, setShowMdPreview] = React.useState({ show: false, text: '', id: '' })
	const [showWarningModal, setShowWarningModal] = React.useState(false)

	const onCancelWarningModal = () => {
		setShowWarningModal(false)
	}

	const onCloseMdPreview = () => setShowMdPreview({ show: false, text: '', id: '' })

	React.useEffect(() => {
		dispatch(fetchFiles(id ?? rootDir))
	}, [id])

	React.useEffect(() => {
		if (!showMdPreview.show && location.href.includes('md')) downloadMdFile(mdId)
	}, [])

	const downloadMdFile = async (mdId: string) => {
		const re = await fileApi.apiFileDownloadGet(mdId)

		setShowMdPreview({ show: true, text: re.data, id: mdId })
		navigate(`/files/${id ?? rootDir}/${mdId}/md/view/`)
	}

	const uploadFile = async (file: RcFile, onProgress: (event: UploadProgressEvent) => void) => {
		if (!file) return

		const { key, uploadId } = await startUpload()

		const chunkSize = 1024 * 1024 * 5
		const totalChunks = Math.ceil(file.size / chunkSize)

		const config: AxiosRequestConfig = {
			headers: {
				uploadId: uploadId,
				key: key,
			},
		}

		let start = 0
		let end = Math.min(chunkSize, file.size)

		await new Promise(async resolve => {
			for (let i = 0; i < totalChunks; i++) {
				const partNumber = i + 1
				onProgress({ percent: (partNumber / totalChunks) * 100 })

				const chunk = file.slice(start, end)
				const uploadedChunks = JSON.parse(localStorage.getItem(file.name))
				const isChunkUploaded = uploadedChunks?.some(
					item => item.partNumber === partNumber && item.ETag !== undefined
				)

				if (!isChunkUploaded) {
					try {
						config.headers['partNumber'] = partNumber
						const ETag = await uploadChunk(chunk, config)

						setUploadedChunks(prev => {
							const prevChunks =
								prev[file.name] || JSON.parse(localStorage.getItem(file.name)) || []

							const newUploadedChunks = [
								...prevChunks,
								{ partNumber, ETag: ETag.replace(/\"/g, '') },
							].sort((a, b) => a.partNumber - b.partNumber)

							localStorage.setItem(file.name, JSON.stringify(newUploadedChunks))
							return { ...prev, [file.name]: newUploadedChunks }
						})
					} catch (error) {
						console.error(error)
					}
				}

				start = end
				end = Math.min(start + chunkSize, file.size)
			}

			resolve(null)
		})

		delete config.headers.partNumber

		config.headers['parentId'] = id ?? rootDir
		config.headers['fileName'] = file.name

		const uploadedFile = await endUpload(config, file.name, setUploadedChunks)
		dispatch(setFile(uploadedFile))
	}

	const onChange = ({
		file,
		fileList,
	}: {
		file: UploadFile<RcFile>
		fileList: UploadProps['fileList']
	}) => {
		if (file.status === 'done') {
			const updatedFileList = fileList.filter(item => item.uid !== file.uid)
			setFileList(updatedFileList)

			message.success(`${file.name} успешно загружен`)
		} else if (file.status === 'error') {
			message.error(`${file.name} не удалось загрузить`)
		} else if (file.status === 'uploading') {
			setFileList(fileList)
		}
	}

	const isExistFile = (file: RcFile) => {
		return files.some(item => item.name === file.name)
	}

	const onContinueButtonClick = () => {
		continueUploadPromise(true)
		setShowWarningModal(false)
	}

	const onCancelButtonClick = () => {
		continueUploadPromise(false)
		setShowWarningModal(false)
	}

	const uploadProps: UploadProps = {
		name: 'file',
		multiple: true,
		fileList: fileList,
		beforeUpload: async file => {
			if (isExistFile(file)) {
				setShowWarningModal(true)

				const shouldContinue = await new Promise<boolean>(resolve => {
					continueUploadPromise = resolve
				})

				return shouldContinue
			}
			setFileList([])
			return true
		},
		onChange,
		customRequest: async info => {
			const { onSuccess, file, onProgress } = info

			await uploadFile(file as RcFile, onProgress)
			onSuccess('Ok')
		},
		showUploadList: false,
	}

	const handleDragEnter = e => {
		e.stopPropagation()
		e.preventDefault()
		setIsDragging(true)
	}

	const handleDragLeave = e => {
		e.stopPropagation()
		e.preventDefault()
		setIsDragging(false)
	}

	const handleDrop = e => {
		e.stopPropagation()
		e.preventDefault()
		setIsDragging(false)
	}

	const handleDragOver = e => {
		e.stopPropagation()
		e.preventDefault()
		setIsDragging(true)
	}

	const handleDragEnd = e => {
		e.preventDefault()
		setIsDragging(false)
	}

	return (
		<>
			<FilesModals />
			<WarningModal
				title='Вы уверены, что хотите перезаписать файл?'
				open={showWarningModal}
				onCancel={onCancelButtonClick}
				onContinue={onContinueButtonClick}
			/>
			<Row>
				<Col span={8}>
					<div
						style={{
							marginTop: '12px',
							position: 'relative',
						}}
						onDragEnter={handleDragEnter}
						onDragLeave={handleDragLeave}
						onDrop={handleDrop}
						onDragOver={handleDragOver}
						onDragEnd={handleDragEnd}
					>
						<UploadDragger uploadProps={uploadProps} isDragging={isDragging} />
						<FilesTable
							uploadProps={uploadProps}
							showMdPreview={showMdPreview}
							downloadMdFile={downloadMdFile}
							onCloseMdPreview={onCloseMdPreview}
							fileList={fileList}
						/>
					</div>
				</Col>
				<Col style={{ margin: '12px 12px 0 12px' }} flex={1}>
					{showMdPreview.show && (
						<div data-color-mode='light'>
							<MDEditor
								value={showMdPreview.text}
								preview={'preview'}
								height={'84vh'}
								hideToolbar={true}
								visibleDragbar={false}
							/>
						</div>
					)}
				</Col>
			</Row>
			{/* {showShareModal.visible && (
                <ShareModal
                    showModal={showShareModal.visible}
                    onCancel={onCancel}
                    roles={roles}
                    getRoles={getRoles}
                    addRole={addRole}
                    id={showShareModal.id}
                />
            )} */}
		</>
	)
}

// const getRoles = async () => {
//     const response = await backendApi.apiRoleGet();

//     setRoles(response.data);
// };

// const addRole = async (fileId: string, roleId: string, shareType: string) => {
//     await backendApi.apiSharePost(fileId, roleId, shareType);
// };

// const [showShareModal, setShowShareModal] = React.useState<{
//     visible: boolean;
//     id: string;
// }>({ visible: false, id: '' });
// const [roles, setRoles] = React.useState<RoleDto[]>([]);

// const onCancel = () => setShowShareModal({ visible: false, id: '' });
