import {
    CheckOutlined,
    DownloadOutlined,
    ExperimentOutlined,
    FileTextOutlined,
    FolderOutlined,
    HeatMapOutlined,
    HomeOutlined,
    ProfileOutlined,
    SettingOutlined,
    SubnodeOutlined,
    TableOutlined,
    UploadOutlined,
    UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import styles from './Sider.module.css';

interface INavigation {
    label: string;
    key: string;
    icon?: React.ReactNode;
    children?: INavigation[];
    isActive?: boolean;
}

const navigation: INavigation[] = [
    {
        label: 'Главная',
        icon: <HomeOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        key: '/',
    },
    {
        label: 'Файлы',
        icon: <FileTextOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        key: '/files',
    },
    {
        label: 'Процессы',
        icon: <ProfileOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        key: 'bpm',
        children: [
            { label: 'Задачи', key: '/bpm/tasks' },
            {
                label: 'Запущенные процессы',
                key: '/bpm/process-instances',
            },
            {
                label: 'Процессы',
                key: '/bpm/processes',
            },
        ],
    },
    {
        label: 'CRM',
        icon: <UserOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        key: 'CRM',
        children: [
            { label: 'Overview', key: '/crm' },
            {
                label: 'Запросы',
                key: '/crm/requests',
            },
        ],
    },
    {
        label: 'CMS',
        icon: <FolderOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        key: 'cms',
        children: [
            { label: 'Overview', key: '/cms' },
            { label: 'Pages', key: '/cms/pages' },
            { label: 'News', key: '/cms/news' },
            { label: 'Menu', key: '/cms/menu' },
            {
                label: 'Settings',
                key: '/cms/settings',
            },
        ],
    },
    {
        label: 'Настройки',
        key: 'settings',
        icon: <SettingOutlined style={{ color: 'white', fontSize: 24 }} rev={undefined} />,
        children: [
            {
                label: 'Пользователи',
                key: '/settings/users',
            },
            { label: 'Роли', key: '/settings/roles' },
        ],
    },
];

const Sider: React.FC = () => {
    const navigate = useNavigate();
    const [navigationMenu, setNavigationMenu] = React.useState<INavigation[]>(navigation);
    const [collapsed, setCollapsed] = React.useState<string>('');
    const roles: string[] = JSON.parse(localStorage.getItem('roles'));

    const closeSubMenu = () => {
        setCollapsed('');
    };

    const subMenuRef = useOutsideClick<HTMLDivElement>(closeSubMenu);

    React.useEffect(() => {
        const changeNavigation = (key: string) => {
            setNavigationMenu(
                navigationMenu.map(itemNav => {
                    const findChildren = itemNav.children?.filter(item => item.key === key);

                    if (itemNav.key === key || findChildren?.length > 0) {
                        itemNav.isActive = true;
                        itemNav?.children?.forEach(item => {
                            if (item.key === key) {
                                item.isActive = true;
                            } else {
                                item.isActive = false;
                            }
                        });
                    } else {
                        itemNav.isActive = false;
                        itemNav?.children?.forEach(item => {
                            item.isActive = false;
                        });
                    }
                    return itemNav;
                }),
            );
        };

        changeNavigation(window.location.pathname);
    }, [window.location.pathname]);

    const checkRole = () => {
        if (!!roles?.find(item => item === 'admin')) {
            return navigationMenu;
        }

        return navigationMenu.map(item => ({
            ...item,
            children: item.children?.filter(child => child.key !== 'users' && child.key !== 'roles'),
        }));
    };

    return (
        <div className={styles.sider}>
            {checkRole().map((itemNav, indexNav) => {
                return (
                    <div
                        style={{ backgroundColor: itemNav.isActive ? '#38649f' : '' }}
                        className={styles.item}
                        onClick={() => {
                            if (itemNav.children) {
                                if (collapsed === itemNav.key) {
                                    closeSubMenu();
                                    return;
                                }
                                setCollapsed(itemNav.key);
                                return;
                            }

                            navigate(itemNav.key);
                        }}
                        key={indexNav}
                    >
                        {itemNav.icon}
                        <div className={styles.itemTitle}>{itemNav.label}</div>
                        <div
                            ref={subMenuRef}
                            className={styles.subMenu}
                            style={{
                                opacity: collapsed === itemNav.key ? '1' : '',
                                visibility: collapsed === itemNav.key ? 'visible' : 'hidden',
                                transition: 'all 0.3s ease',
                            }}
                        >
                            {itemNav.children?.map((item, index) => (
                                <div
                                    style={{ backgroundColor: item.isActive ? '#38649f' : '' }}
                                    key={index}
                                    className={styles.subMenuItem}
                                    onClick={() => navigate(item.key)}
                                >
                                    {item.label}
                                    {item.isActive && <CheckOutlined rev={undefined} />}
                                </div>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Sider;
