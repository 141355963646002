import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosRequestConfig } from 'axios'
import { fileApi } from '../../api/backend-wrapper'
import { rootDir } from '../../components/files/FilesLayout'

export const fetchFiles = createAsyncThunk(
	'files/fetch-files',
	async (id: string, { rejectWithValue }) => {
		try {
			const response = await fileApi.apiFileListByParentIdGet(id)

			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteFile = createAsyncThunk(
	'files/delete-file',
	async (fileId: string, { rejectWithValue }) => {
		try {
			const response = await fileApi.apiFileDeleteDelete(fileId)

			return fileId
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const createFile = createAsyncThunk(
	'files/create-file',
	async (
		{ fileName, extension, parentId }: { fileName: string; extension: string; parentId: string },
		{ rejectWithValue }
	) => {
		try {
			const response = await fileApi.apiFileCreatePost(extension, parentId, fileName)

			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const createFolder = createAsyncThunk(
	'files/create-folder',
	async ({ fileName, parentId }: { fileName: string; parentId: string }, { rejectWithValue }) => {
		try {
			const response = await fileApi.apiFileCreateFolderPost(parentId, fileName)

			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const uploadFile = createAsyncThunk(
	'files/upload-file',
	async (
		{
			parentId,
			formData,
			config,
		}: { parentId: string; formData: FormData; config: AxiosRequestConfig },
		{ rejectWithValue }
	) => {
		try {
			const response = await axios.post(
				`/api/file/upload?parentId=${parentId ?? rootDir}`,
				formData,
				config
			)

			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)
