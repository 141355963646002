/* tslint:disable */
/* eslint-disable */
/**
 * office API
 * office API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    'token'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthResponse
     */
    'roles'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ByteArrayContent
 */
export interface ByteArrayContent {
    /**
     * 
     * @type {ContentType}
     * @memberof ByteArrayContent
     */
    'contentType'?: ContentType;
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof ByteArrayContent
     */
    'status'?: HttpStatusCode;
}
/**
 * 
 * @export
 * @interface ContentType
 */
export interface ContentType {
    /**
     * 
     * @type {string}
     * @memberof ContentType
     */
    'contentType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ContentType
     */
    'contentSubtype'?: string;
    /**
     * 
     * @type {Array<HeaderValueParam>}
     * @memberof ContentType
     */
    'parameters'?: Array<HeaderValueParam>;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'size'?: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'parentId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'fileType'?: FileDtoFileTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'updatedAt'?: string;
    /**
     * 
     * @type {FileRoleRightDto}
     * @memberof FileDto
     */
    'right'?: FileRoleRightDto;
}

export const FileDtoFileTypeEnum = {
    Docx: 'DOCX',
    Pdf: 'PDF',
    Xlsx: 'XLSX',
    Pptx: 'PPTX',
    Txt: 'TXT',
    Jpg: 'JPG',
    Png: 'PNG',
    Gif: 'GIF',
    Mp4: 'MP4',
    Mp3: 'MP3',
    Zip: 'ZIP',
    Rar: 'RAR',
    Markdown: 'MARKDOWN'
} as const;

export type FileDtoFileTypeEnum = typeof FileDtoFileTypeEnum[keyof typeof FileDtoFileTypeEnum];

/**
 * 
 * @export
 * @interface FileInfoResponse
 */
export interface FileInfoResponse {
    /**
     * 
     * @type {FileDto}
     * @memberof FileInfoResponse
     */
    'file'?: FileDto;
    /**
     * 
     * @type {ServerInfoDto}
     * @memberof FileInfoResponse
     */
    'serverInfo'?: ServerInfoDto;
}
/**
 * 
 * @export
 * @interface FileListingResponseDto
 */
export interface FileListingResponseDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof FileListingResponseDto
     */
    'chain'?: Array<FileDto>;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof FileListingResponseDto
     */
    'list'?: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface FilePartDto
 */
export interface FilePartDto {
    /**
     * 
     * @type {number}
     * @memberof FilePartDto
     */
    'partNumber'?: number;
    /**
     * 
     * @type {string}
     * @memberof FilePartDto
     */
    'ETag'?: string;
}
/**
 * 
 * @export
 * @interface FileRoleRightDto
 */
export interface FileRoleRightDto {
    /**
     * 
     * @type {string}
     * @memberof FileRoleRightDto
     */
    'fileId'?: string;
    /**
     * 
     * @type {number}
     * @memberof FileRoleRightDto
     */
    'roleId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof FileRoleRightDto
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileRoleRightDto
     */
    'readContents'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FileRoleRightDto
     */
    'write'?: boolean;
}
/**
 * 
 * @export
 * @interface FileShareDto
 */
export interface FileShareDto {
    /**
     * 
     * @type {number}
     * @memberof FileShareDto
     */
    'id'?: number;
    /**
     * 
     * @type {FileDto}
     * @memberof FileShareDto
     */
    'file'?: FileDto;
    /**
     * 
     * @type {RoleDto}
     * @memberof FileShareDto
     */
    'role'?: RoleDto;
    /**
     * 
     * @type {string}
     * @memberof FileShareDto
     */
    'shareType'?: string;
    /**
     * 
     * @type {string}
     * @memberof FileShareDto
     */
    'createdAt'?: string;
    /**
     * 
     * @type {UserDto}
     * @memberof FileShareDto
     */
    'createdBy'?: UserDto;
}
/**
 * 
 * @export
 * @interface HeaderValueParam
 */
export interface HeaderValueParam {
    /**
     * 
     * @type {string}
     * @memberof HeaderValueParam
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof HeaderValueParam
     */
    'value'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof HeaderValueParam
     */
    'escapeValue'?: boolean;
}
/**
 * 
 * @export
 * @interface HttpStatusCode
 */
export interface HttpStatusCode {
    /**
     * 
     * @type {number}
     * @memberof HttpStatusCode
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof HttpStatusCode
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface LoginPreDTO
 */
export interface LoginPreDTO {
    /**
     * 
     * @type {PollLoginPreDTO}
     * @memberof LoginPreDTO
     */
    'poll'?: PollLoginPreDTO;
    /**
     * 
     * @type {string}
     * @memberof LoginPreDTO
     */
    'login'?: string;
}
/**
 * 
 * @export
 * @interface MultipartUpload
 */
export interface MultipartUpload {
    /**
     * 
     * @type {string}
     * @memberof MultipartUpload
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof MultipartUpload
     */
    'upload_id': string;
}
/**
 * 
 * @export
 * @interface PollLoginPreDTO
 */
export interface PollLoginPreDTO {
    /**
     * 
     * @type {string}
     * @memberof PollLoginPreDTO
     */
    'token'?: string;
    /**
     * 
     * @type {string}
     * @memberof PollLoginPreDTO
     */
    'endpoint'?: string;
}
/**
 * 
 * @export
 * @interface PollResponse
 */
export interface PollResponse {
    /**
     * 
     * @type {string}
     * @memberof PollResponse
     */
    'server'?: string;
    /**
     * 
     * @type {string}
     * @memberof PollResponse
     */
    'loginName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PollResponse
     */
    'appPassword'?: string;
}
/**
 * 
 * @export
 * @interface RoleDto
 */
export interface RoleDto {
    /**
     * 
     * @type {number}
     * @memberof RoleDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDto
     */
    'description'?: string;
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof RoleDto
     */
    'users'?: Array<UserDto>;
}
/**
 * 
 * @export
 * @interface ServerInfoDto
 */
export interface ServerInfoDto {
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'version'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'serverName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'bundleHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'backendUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof ServerInfoDto
     */
    'externalUrl'?: string;
}
/**
 * 
 * @export
 * @interface ServerStatus
 */
export interface ServerStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ServerStatus
     */
    'installed'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerStatus
     */
    'maintenance'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServerStatus
     */
    'needsDbUpgrade'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServerStatus
     */
    'version'?: string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'login'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'password'?: string;
    /**
     * 
     * @type {Array<RoleDto>}
     * @memberof UserDto
     */
    'roles'?: Array<RoleDto>;
}
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    'userId'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiInfoGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiInfoGet(options?: any): AxiosPromise<ServerInfoDto> {
            return localVarFp.apiInfoGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiInfoGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiInfoGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileCreateFolderPost: async (parentId?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/create-folder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileCreatePost: async (type?: string, parentId?: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (parentId !== undefined) {
                localVarQueryParameter['parentId'] = parentId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteDelete: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileInfoIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFileInfoIdGet', 'id', id)
            const localVarPath = `/api/file/info/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileListByParentIdGet: async (id?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/list-by-parent-id`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {number} [partNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadChunkPost: async (chunk: any, uploadId?: string, key?: string, partNumber?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('apiFileUploadChunkPost', 'chunk', chunk)
            const localVarPath = `/api/file/upload/chunk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uploadId !== undefined && uploadId !== null) {
                localVarHeaderParameter['uploadId'] = String(uploadId);
            }

            if (key !== undefined && key !== null) {
                localVarHeaderParameter['key'] = String(key);
            }

            if (partNumber !== undefined && partNumber !== null) {
                localVarHeaderParameter['partNumber'] = String(JSON.stringify(partNumber));
            }


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<FilePartDto>} filePartDto 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {string} [parentId] 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadEndPost: async (filePartDto: Array<FilePartDto>, uploadId?: string, key?: string, parentId?: string, filename?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'filePartDto' is not null or undefined
            assertParamExists('apiFileUploadEndPost', 'filePartDto', filePartDto)
            const localVarPath = `/api/file/upload/end`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (uploadId !== undefined && uploadId !== null) {
                localVarHeaderParameter['uploadId'] = String(uploadId);
            }

            if (key !== undefined && key !== null) {
                localVarHeaderParameter['key'] = String(key);
            }

            if (parentId !== undefined && parentId !== null) {
                localVarHeaderParameter['parentId'] = String(parentId);
            }

            if (filename !== undefined && filename !== null) {
                localVarHeaderParameter['filename'] = String(filename);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filePartDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * start multipart upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadStartPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/upload/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileCreateFolderPost(parentId?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileCreateFolderPost(parentId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileCreatePost(type?: string, parentId?: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileCreatePost(type, parentId, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDeleteDelete(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDeleteDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileDownloadGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileDownloadGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileInfoIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileInfoIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileListByParentIdGet(id?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileListingResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileListByParentIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {number} [partNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileUploadChunkPost(chunk: any, uploadId?: string, key?: string, partNumber?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileUploadChunkPost(chunk, uploadId, key, partNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<FilePartDto>} filePartDto 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {string} [parentId] 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileUploadEndPost(filePartDto: Array<FilePartDto>, uploadId?: string, key?: string, parentId?: string, filename?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileUploadEndPost(filePartDto, uploadId, key, parentId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * start multipart upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFileUploadStartPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipartUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFileUploadStartPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileCreateFolderPost(parentId?: string, name?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFileCreateFolderPost(parentId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [type] 
         * @param {string} [parentId] 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileCreatePost(type?: string, parentId?: string, name?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.apiFileCreatePost(type, parentId, name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDeleteDelete(id?: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiFileDeleteDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileDownloadGet(id?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileDownloadGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileInfoIdGet(id: string, options?: any): AxiosPromise<FileInfoResponse> {
            return localVarFp.apiFileInfoIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileListByParentIdGet(id?: string, options?: any): AxiosPromise<FileListingResponseDto> {
            return localVarFp.apiFileListByParentIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {any} chunk 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {number} [partNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadChunkPost(chunk: any, uploadId?: string, key?: string, partNumber?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileUploadChunkPost(chunk, uploadId, key, partNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<FilePartDto>} filePartDto 
         * @param {string} [uploadId] 
         * @param {string} [key] 
         * @param {string} [parentId] 
         * @param {string} [filename] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadEndPost(filePartDto: Array<FilePartDto>, uploadId?: string, key?: string, parentId?: string, filename?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiFileUploadEndPost(filePartDto, uploadId, key, parentId, filename, options).then((request) => request(axios, basePath));
        },
        /**
         * start multipart upload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFileUploadStartPost(options?: any): AxiosPromise<MultipartUpload> {
            return localVarFp.apiFileUploadStartPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @param {string} [parentId] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileCreateFolderPost(parentId?: string, name?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileCreateFolderPost(parentId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [type] 
     * @param {string} [parentId] 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileCreatePost(type?: string, parentId?: string, name?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileCreatePost(type, parentId, name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDeleteDelete(id?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDeleteDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileDownloadGet(id?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileDownloadGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileInfoIdGet(id: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileInfoIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileListByParentIdGet(id?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileListByParentIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {any} chunk 
     * @param {string} [uploadId] 
     * @param {string} [key] 
     * @param {number} [partNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileUploadChunkPost(chunk: any, uploadId?: string, key?: string, partNumber?: number, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileUploadChunkPost(chunk, uploadId, key, partNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<FilePartDto>} filePartDto 
     * @param {string} [uploadId] 
     * @param {string} [key] 
     * @param {string} [parentId] 
     * @param {string} [filename] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileUploadEndPost(filePartDto: Array<FilePartDto>, uploadId?: string, key?: string, parentId?: string, filename?: string, options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileUploadEndPost(filePartDto, uploadId, key, parentId, filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * start multipart upload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public apiFileUploadStartPost(options?: AxiosRequestConfig) {
        return FileApiFp(this.configuration).apiFileUploadStartPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NextcloudApi - axios parameter creator
 * @export
 */
export const NextcloudApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2FlowTokenGet: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('nextcloudIndexPhpLoginV2FlowTokenGet', 'token', token)
            const localVarPath = `/nextcloud/index.php/login/v2/flow/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * аутентификация прошла получается \"auth-form\"
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2FlowTokenPost: async (token: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('nextcloudIndexPhpLoginV2FlowTokenPost', 'token', token)
            const localVarPath = `/nextcloud/index.php/login/v2/flow/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * poll process
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2PollPost: async (token?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/index.php/login/v2/poll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * init process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2Post: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/index.php/login/v2`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV1PhpCloudCapabilitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/ocs/v1.php/cloud/capabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV1PhpCloudUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/ocs/v1.php/cloud/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV2PhpCloudCapabilitiesGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/ocs/v2.php/cloud/capabilities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserHead: async (user: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserHead', 'user', user)
            const localVarPath = `/nextcloud/remote.php/dav/files/{user}`
                .replace(`{${"user"}}`, encodeURIComponent(String(user)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathDelete: async (path: Array<string>, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathDelete', 'path', path)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathDelete', 'userId', userId)
            const localVarPath = `/nextcloud/remote.php/dav/files/{userId}/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathGet: async (path: Array<string>, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathGet', 'path', path)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathGet', 'userId', userId)
            const localVarPath = `/nextcloud/remote.php/dav/files/{userId}/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {number} [oCTotalLength] 
         * @param {string} [oCChecksum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathPut: async (path: Array<string>, userId: string, oCTotalLength?: number, oCChecksum?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathPut', 'path', path)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('nextcloudRemotePhpDavFilesUserIdPathPut', 'userId', userId)
            const localVarPath = `/nextcloud/remote.php/dav/files/{userId}/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (oCTotalLength !== undefined && oCTotalLength !== null) {
                localVarHeaderParameter['OC-Total-Length'] = String(JSON.stringify(oCTotalLength));
            }

            if (oCChecksum !== undefined && oCChecksum !== null) {
                localVarHeaderParameter['OC-Checksum'] = String(oCChecksum);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudStatusPhpGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/nextcloud/status.php`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NextcloudApi - functional programming interface
 * @export
 */
export const NextcloudApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NextcloudApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudIndexPhpLoginV2FlowTokenGet(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudIndexPhpLoginV2FlowTokenGet(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * аутентификация прошла получается \"auth-form\"
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudIndexPhpLoginV2FlowTokenPost(token: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudIndexPhpLoginV2FlowTokenPost(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * poll process
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudIndexPhpLoginV2PollPost(token?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PollResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudIndexPhpLoginV2PollPost(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * init process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudIndexPhpLoginV2Post(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginPreDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudIndexPhpLoginV2Post(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudOcsV1PhpCloudCapabilitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudOcsV1PhpCloudCapabilitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudOcsV1PhpCloudUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudOcsV1PhpCloudUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudOcsV2PhpCloudCapabilitiesGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudOcsV2PhpCloudCapabilitiesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudRemotePhpDavFilesUserHead(user: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudRemotePhpDavFilesUserHead(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudRemotePhpDavFilesUserIdPathDelete(path: Array<string>, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudRemotePhpDavFilesUserIdPathDelete(path, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudRemotePhpDavFilesUserIdPathGet(path: Array<string>, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ByteArrayContent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudRemotePhpDavFilesUserIdPathGet(path, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {number} [oCTotalLength] 
         * @param {string} [oCChecksum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudRemotePhpDavFilesUserIdPathPut(path: Array<string>, userId: string, oCTotalLength?: number, oCChecksum?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudRemotePhpDavFilesUserIdPathPut(path, userId, oCTotalLength, oCChecksum, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nextcloudStatusPhpGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServerStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nextcloudStatusPhpGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NextcloudApi - factory interface
 * @export
 */
export const NextcloudApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NextcloudApiFp(configuration)
    return {
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2FlowTokenGet(token: string, options?: any): AxiosPromise<object> {
            return localVarFp.nextcloudIndexPhpLoginV2FlowTokenGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * аутентификация прошла получается \"auth-form\"
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2FlowTokenPost(token: string, options?: any): AxiosPromise<object> {
            return localVarFp.nextcloudIndexPhpLoginV2FlowTokenPost(token, options).then((request) => request(axios, basePath));
        },
        /**
         * poll process
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2PollPost(token?: string, options?: any): AxiosPromise<PollResponse> {
            return localVarFp.nextcloudIndexPhpLoginV2PollPost(token, options).then((request) => request(axios, basePath));
        },
        /**
         * init process
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudIndexPhpLoginV2Post(options?: any): AxiosPromise<LoginPreDTO> {
            return localVarFp.nextcloudIndexPhpLoginV2Post(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV1PhpCloudCapabilitiesGet(options?: any): AxiosPromise<ServerStatus> {
            return localVarFp.nextcloudOcsV1PhpCloudCapabilitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV1PhpCloudUserGet(options?: any): AxiosPromise<UserInfo> {
            return localVarFp.nextcloudOcsV1PhpCloudUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudOcsV2PhpCloudCapabilitiesGet(options?: any): AxiosPromise<ServerStatus> {
            return localVarFp.nextcloudOcsV2PhpCloudCapabilitiesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} user 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserHead(user: string, options?: any): AxiosPromise<object> {
            return localVarFp.nextcloudRemotePhpDavFilesUserHead(user, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathDelete(path: Array<string>, userId: string, options?: any): AxiosPromise<object> {
            return localVarFp.nextcloudRemotePhpDavFilesUserIdPathDelete(path, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathGet(path: Array<string>, userId: string, options?: any): AxiosPromise<ByteArrayContent> {
            return localVarFp.nextcloudRemotePhpDavFilesUserIdPathGet(path, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} path 
         * @param {string} userId 
         * @param {number} [oCTotalLength] 
         * @param {string} [oCChecksum] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudRemotePhpDavFilesUserIdPathPut(path: Array<string>, userId: string, oCTotalLength?: number, oCChecksum?: string, options?: any): AxiosPromise<object> {
            return localVarFp.nextcloudRemotePhpDavFilesUserIdPathPut(path, userId, oCTotalLength, oCChecksum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nextcloudStatusPhpGet(options?: any): AxiosPromise<ServerStatus> {
            return localVarFp.nextcloudStatusPhpGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NextcloudApi - object-oriented interface
 * @export
 * @class NextcloudApi
 * @extends {BaseAPI}
 */
export class NextcloudApi extends BaseAPI {
    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudIndexPhpLoginV2FlowTokenGet(token: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudIndexPhpLoginV2FlowTokenGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * аутентификация прошла получается \"auth-form\"
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudIndexPhpLoginV2FlowTokenPost(token: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudIndexPhpLoginV2FlowTokenPost(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * poll process
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudIndexPhpLoginV2PollPost(token?: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudIndexPhpLoginV2PollPost(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * init process
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudIndexPhpLoginV2Post(options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudIndexPhpLoginV2Post(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudOcsV1PhpCloudCapabilitiesGet(options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudOcsV1PhpCloudCapabilitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudOcsV1PhpCloudUserGet(options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudOcsV1PhpCloudUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudOcsV2PhpCloudCapabilitiesGet(options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudOcsV2PhpCloudCapabilitiesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} user 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudRemotePhpDavFilesUserHead(user: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudRemotePhpDavFilesUserHead(user, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} path 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudRemotePhpDavFilesUserIdPathDelete(path: Array<string>, userId: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudRemotePhpDavFilesUserIdPathDelete(path, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} path 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudRemotePhpDavFilesUserIdPathGet(path: Array<string>, userId: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudRemotePhpDavFilesUserIdPathGet(path, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} path 
     * @param {string} userId 
     * @param {number} [oCTotalLength] 
     * @param {string} [oCChecksum] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudRemotePhpDavFilesUserIdPathPut(path: Array<string>, userId: string, oCTotalLength?: number, oCChecksum?: string, options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudRemotePhpDavFilesUserIdPathPut(path, userId, oCTotalLength, oCChecksum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextcloudApi
     */
    public nextcloudStatusPhpGet(options?: AxiosRequestConfig) {
        return NextcloudApiFp(this.configuration).nextcloudStatusPhpGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RoleApi - axios parameter creator
 * @export
 */
export const RoleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleCreatePost: async (name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiRoleIdGet', 'id', id)
            const localVarPath = `/api/role/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoleApi - functional programming interface
 * @export
 */
export const RoleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RoleApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleCreatePost(name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleCreatePost(name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RoleDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiRoleIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RoleDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiRoleIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RoleApi - factory interface
 * @export
 */
export const RoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RoleApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleCreatePost(name?: string, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.apiRoleCreatePost(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleGet(options?: any): AxiosPromise<Array<RoleDto>> {
            return localVarFp.apiRoleGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiRoleIdGet(id: number, options?: any): AxiosPromise<RoleDto> {
            return localVarFp.apiRoleIdGet(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RoleApi - object-oriented interface
 * @export
 * @class RoleApi
 * @extends {BaseAPI}
 */
export class RoleApi extends BaseAPI {
    /**
     * 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleCreatePost(name?: string, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleCreatePost(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleGet(options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoleApi
     */
    public apiRoleIdGet(id: number, options?: AxiosRequestConfig) {
        return RoleApiFp(this.configuration).apiRoleIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShareApi - axios parameter creator
 * @export
 */
export const ShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShareAllGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/share/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {string} [roleId] 
         * @param {string} [shareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharePost: async (fileId?: string, roleId?: string, shareType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }

            if (shareType !== undefined) {
                localVarQueryParameter['shareType'] = shareType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareApi - functional programming interface
 * @export
 */
export const ShareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiShareAllGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileShareDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiShareAllGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {string} [roleId] 
         * @param {string} [shareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSharePost(fileId?: string, roleId?: string, shareType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSharePost(fileId, roleId, shareType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShareApi - factory interface
 * @export
 */
export const ShareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShareApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiShareAllGet(options?: any): AxiosPromise<Array<FileShareDto>> {
            return localVarFp.apiShareAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fileId] 
         * @param {string} [roleId] 
         * @param {string} [shareType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSharePost(fileId?: string, roleId?: string, shareType?: string, options?: any): AxiosPromise<object> {
            return localVarFp.apiSharePost(fileId, roleId, shareType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShareApi - object-oriented interface
 * @export
 * @class ShareApi
 * @extends {BaseAPI}
 */
export class ShareApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public apiShareAllGet(options?: AxiosRequestConfig) {
        return ShareApiFp(this.configuration).apiShareAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [fileId] 
     * @param {string} [roleId] 
     * @param {string} [shareType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public apiSharePost(fileId?: string, roleId?: string, shareType?: string, options?: AxiosRequestConfig) {
        return ShareApiFp(this.configuration).apiSharePost(fileId, roleId, shareType, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddToRolePost: async (userId?: number, roleId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/add-to-role`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['roleId'] = roleId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost: async (authRequest: AuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiUserCreatePost', 'authRequest', authRequest)
            const localVarPath = `/api/user/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/get-me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdDelete', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiUserIdGet', 'id', id)
            const localVarPath = `/api/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost: async (authRequest: AuthRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'authRequest' is not null or undefined
            assertParamExists('apiUserLoginPost', 'authRequest', authRequest)
            const localVarPath = `/api/user/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTokenPost: async (userLogin?: string, refreshToken?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/updateToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userLogin !== undefined) {
                localVarQueryParameter['userLogin'] = userLogin;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserAddToRolePost(userId?: number, roleId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserAddToRolePost(userId, roleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserCreatePost(authRequest: AuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserCreatePost(authRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGetMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGetMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdDelete(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserIdGet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserLoginPost(authRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUpdateTokenPost(userLogin, refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [userId] 
         * @param {number} [roleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserAddToRolePost(userId?: number, roleId?: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiUserAddToRolePost(userId, roleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserCreatePost(authRequest: AuthRequest, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserCreatePost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGetMeGet(options?: any): AxiosPromise<string> {
            return localVarFp.apiUserGetMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdDelete(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.apiUserIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserIdGet(id: number, options?: any): AxiosPromise<UserDto> {
            return localVarFp.apiUserIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthRequest} authRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserLoginPost(authRequest: AuthRequest, options?: any): AxiosPromise<AuthResponse> {
            return localVarFp.apiUserLoginPost(authRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [userLogin] 
         * @param {string} [refreshToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiUserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {number} [userId] 
     * @param {number} [roleId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserAddToRolePost(userId?: number, roleId?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserAddToRolePost(userId, roleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserCreatePost(authRequest: AuthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserCreatePost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGetMeGet(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserGetMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdDelete(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserIdGet(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthRequest} authRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserLoginPost(authRequest: AuthRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserLoginPost(authRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [userLogin] 
     * @param {string} [refreshToken] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUpdateTokenPost(userLogin?: string, refreshToken?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).apiUserUpdateTokenPost(userLogin, refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebdavApi - axios parameter creator
 * @export
 */
export const WebdavApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 4.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webdavIdGet', 'id', id)
            const localVarPath = `/webdav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 2, 5
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdHead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webdavIdHead', 'id', id)
            const localVarPath = `/webdav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 3.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdOptions: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webdavIdOptions', 'id', id)
            const localVarPath = `/webdav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdPut: async (id: string, requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('webdavIdPut', 'id', id)
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('webdavIdPut', 'requestBody', requestBody)
            const localVarPath = `/webdav/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 1. (только при первом запуске ворд, если уже открыто - не запросит)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webdav`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebdavApi - functional programming interface
 * @export
 */
export const WebdavApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebdavApiAxiosParamCreator(configuration)
    return {
        /**
         * 4.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webdavIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webdavIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 2, 5
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webdavIdHead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webdavIdHead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 3.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webdavIdOptions(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webdavIdOptions(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webdavIdPut(id: string, requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webdavIdPut(id, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 1. (только при первом запуске ворд, если уже открыто - не запросит)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webdavOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webdavOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebdavApi - factory interface
 * @export
 */
export const WebdavApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebdavApiFp(configuration)
    return {
        /**
         * 4.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdGet(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.webdavIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 2, 5
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdHead(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.webdavIdHead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 3.
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdOptions(id: string, options?: any): AxiosPromise<object> {
            return localVarFp.webdavIdOptions(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavIdPut(id: string, requestBody: Array<string>, options?: any): AxiosPromise<object> {
            return localVarFp.webdavIdPut(id, requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 1. (только при первом запуске ворд, если уже открыто - не запросит)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webdavOptions(options?: any): AxiosPromise<object> {
            return localVarFp.webdavOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebdavApi - object-oriented interface
 * @export
 * @class WebdavApi
 * @extends {BaseAPI}
 */
export class WebdavApi extends BaseAPI {
    /**
     * 4.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebdavApi
     */
    public webdavIdGet(id: string, options?: AxiosRequestConfig) {
        return WebdavApiFp(this.configuration).webdavIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 2, 5
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebdavApi
     */
    public webdavIdHead(id: string, options?: AxiosRequestConfig) {
        return WebdavApiFp(this.configuration).webdavIdHead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 3.
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebdavApi
     */
    public webdavIdOptions(id: string, options?: AxiosRequestConfig) {
        return WebdavApiFp(this.configuration).webdavIdOptions(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebdavApi
     */
    public webdavIdPut(id: string, requestBody: Array<string>, options?: AxiosRequestConfig) {
        return WebdavApiFp(this.configuration).webdavIdPut(id, requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 1. (только при первом запуске ворд, если уже открыто - не запросит)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebdavApi
     */
    public webdavOptions(options?: AxiosRequestConfig) {
        return WebdavApiFp(this.configuration).webdavOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


