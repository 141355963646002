import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import { Site, Page, DefaultApiFactory } from "../generated/backend";
import {officeApi} from "../api/office-wrapper";

export interface CmsState {
    sites: Site[],
    pages: Page[]
}

const initialState: CmsState = {
    sites: [],
    pages: []
}

export const getSites = createAsyncThunk(
    'cms/getSites',
    async ( thunkAPI) => {

        // const response = await api.siteGetAll() .userInfoList({
        //     headers: {
        //         "Authorization": "Bearer " + token
        //     }
        // })
        const response = await api.siteGetAll()

        return response.data
    }
)

export const createSite = createAsyncThunk(
    'cms/createSite',
    async ( thunkAPI) => {
        const response = await api.siteGetAll()

        return response.data
    }
)

// export const login = createAsyncThunk(
//     'general/login',
//     async (payload: {username: string, password: string}) => {
//
//         const api = new Api({
//             baseURL: "http://localhost:8080"
//         });
//
//         const form = new FormData();
//         form.append('grant_type', 'password');
//         form.append('username', payload.username);
//         form.append('password', payload.password);
//
//
//         const response = await api.oauth.tokenCreate(
//             // @ts-ignore todo types error
//             form,
//             {
//                 headers: {
//                     Authorization: "Basic " + btoa("client" + ":" + "secret"),
//                 },
//             }
//         );
//
//         return response.data
//     }
// )

export const cmsSlice = createSlice({
    name: 'cms',
    initialState,
    reducers: {
        // changeUsername: (state, action: PayloadAction<string>) => {
        //     state.username = action.payload
        // },
        // changePassword: (state, action: PayloadAction<string>) => {
        //     state.password = action.payload
        // },
        // logout: (state) => {
        //     state.token = null
        // },
    },
    extraReducers: (builder) => {

        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(getSites.fulfilled, (state, action) => {
                state.sites = action.payload
            })
        //     .addCase(fetchUserInfo.rejected, (state, action) => {
        //         // Add user to the state array
        //         //state.entities.push(action.payload)
        //         state.initial = false
        //         state.token = null
        //     })
        //     .addCase(login.fulfilled, (state, action) => {
        //         // Add user to the state array
        //         state.token = action.payload.access_token
        //
        //         //store token
        //         localStorage.setItem("token", state.token)
        //     })
        //     .addCase(login.rejected, (state, action) => {
        //         // Add user to the state array
        //         console.log(11);
        //     })
        // //todo add cases (errors etc)
    },
})

// Action creators are generated for each case reducer function
export const { } = cmsSlice.actions

export default cmsSlice.reducer