// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AGkgSrqQKsG6opY9xwkA{text-align:center;width:100%;height:100%;display:flex;align-items:center;justify-content:center}.ycqd7sUQqZIOhmz6GO99{text-align:center;width:100vw;height:100vh;display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./components/UI/Loader/Loader.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,sBACI,iBAAA,CACA,WAAA,CACA,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".container {\n    text-align: center;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.generalContainer {\n    text-align: center;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AGkgSrqQKsG6opY9xwkA",
	"generalContainer": "ycqd7sUQqZIOhmz6GO99"
};
export default ___CSS_LOADER_EXPORT___;
