import { Button, Space } from 'antd'
import React from 'react'
import { DeleteModal } from '../Modals/DeleteModal/DeleteModal'

type PopupProps = {
	x?: number
	y?: number
	visible?: boolean
	id: string
	// setShowShareModal: ({ visible, id }: { visible: boolean; id: string }) => void;
}
export const Popup: React.FC<PopupProps> = ({ x, y, visible, id }) => {
	return (
		<>
			<div
				style={{
					left: `${x - 100}px`,
					top: `${y - 135}px`,
					position: 'absolute',
					borderRadius: 4,
					boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
					padding: 4,
					background: '#FFFFFF',
				}}
				hidden={!visible}
			>
				<Space direction={'vertical'}>
					<Button
						size={'small'}
						style={{ width: '100%' }}
						type={'text'}
						// onClick={() => setShowShareModal({ visible: true, id })}
					>
						Поделиться
					</Button>
					<DeleteModal fileId={id} isPopup />
				</Space>
			</div>
		</>
	)
}
