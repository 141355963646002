import { createAsyncThunk } from '@reduxjs/toolkit'
import { userApi } from '../../api/backend-wrapper'
import { RoleDto, UserDto } from '../../generated/backend'

export const fetchUsers = createAsyncThunk('users/fetch-users', async (_, { rejectWithValue }) => {
	try {
		const response = await userApi.apiUserGet()

		return response.data
	} catch (error) {
		return rejectWithValue(error)
	}
})

export const createUser = createAsyncThunk(
	'users/create-user',
	async (user: UserDto, { rejectWithValue }) => {
		try {
			const response = await userApi.apiUserCreatePost({
				username: user.login,
				password: user.password,
			})

			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const deleteUser = createAsyncThunk(
	'users/delete-user',
	async (userId: number, { rejectWithValue }) => {
		try {
			await userApi.apiUserIdDelete(userId)

			return userId
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const fetchUserById = createAsyncThunk(
	'users/fetch-user-by-id',
	async (userId: number, { rejectWithValue }) => {
		try {
			const response = await userApi.apiUserIdGet(userId)
			console.log(response.data)
			return response.data
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)

export const attachRoleToUser = createAsyncThunk(
	'users/attach-role-to-user',
	async (
		{ userId, roleId, filteredRoles }: { userId: number; roleId: number; filteredRoles: RoleDto[] },
		{ rejectWithValue }
	) => {
		try {
			await userApi.apiUserAddToRolePost(userId, roleId)

			return filteredRoles
		} catch (error) {
			return rejectWithValue(error)
		}
	}
)
