import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../redux/store';
import { createRole } from '../../redux/roles/roles.actions';

type RoleAddModalProps = {};

export const RoleAddModal: React.FC<RoleAddModalProps> = ({}) => {
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();

    const onCreateRole = () => {
        setShowModal(true);
    };

    const onCancel = () => {
        setShowModal(false);
    };

    return (
        <>
            <Button type={'text'} icon={<PlusOutlined rev={undefined} />} onClick={onCreateRole}>
                Создать роль
            </Button>
            <Modal centered={true} open={showModal} onCancel={onCancel} title={'Cоздание роли'} footer={false}>
                <Form
                    onFinish={values => {
                        dispatch(createRole(values.name)).then(() => {
                            onCancel();
                        });
                    }}
                    form={form}
                    layout='vertical'
                >
                    <Form.Item name={'name'} label={'Название'}>
                        <Input placeholder={'Название'} size='large' />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <Button type={'primary'} htmlType={'submit'}>
                                Создать
                            </Button>
                            <Button onClick={onCancel}>Отмена</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
