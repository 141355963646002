import { createAsyncThunk } from '@reduxjs/toolkit'
import { backendApi, userApi } from '../../api/backend-wrapper'

export const fetchUserInfo = createAsyncThunk(
	'general/fetchUserInfo',
	async (token: string, { rejectWithValue }) => {
		try {
			const response = await userApi.apiUserGetMeGet()

			return response.data
		} catch (e) {
			return rejectWithValue(e.message)
		}
	}
)

export const login = createAsyncThunk(
	'general/login',
	async (payload: { username: string; password: string }, { rejectWithValue }) => {
		const { username, password } = payload
		try {
			const response = await userApi.apiUserLoginPost({
				username,
				password,
			})

			return response.data
		} catch (e) {
			return rejectWithValue('Неверный логин или пароль')
		}
	}
)

export const fetchServerInfo = createAsyncThunk(
	'general/fetchServerInfo',
	async (payload, { rejectWithValue }) => {
		try {
			const response = await backendApi.apiInfoGet()

			return response.data
		} catch (e) {
			return rejectWithValue('Нет данных о версии или сервере')
		}
	}
)
