import { LoadingOutlined } from '@ant-design/icons'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from '../../../redux/store'

interface BreadcrumbsProps {}

const EXCLUDEDPATHS = ['settings', 'crm', 'cms']

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({}) => {
	const navigate = useNavigate()

	const location = useLocation()
	const paths = location.pathname.split('/').filter(path => path !== '')
	const { chain } = useSelector((state: RootState) => state.files)
	const { users } = useSelector((state: RootState) => state.users)
	const { user } = useSelector((state: RootState) => state.users.selected)
	const { roles } = useSelector((state: RootState) => state.roles)
	const { role } = useSelector((state: RootState) => state.roles.selected)

	const getTitle = (str: string) => {
		switch (str) {
			case 'files':
				return 'Файлы'
			case 'settings':
				return 'Настройки'
			case 'users':
				return 'Пользователи'
			case 'roles':
				return 'Роли'
			case '00000000-0000-0000-0000-000000000000':
				return 'Корневая папка'
		}

		const foundItem = [...chain, ...users, ...roles, user, role]?.find(
			item => item?.id === str || item?.id === +str
		)

		if (!foundItem) return

		if (paths.includes('users') && 'login' in foundItem) return foundItem?.login

		if ('name' in foundItem) return foundItem?.name
	}

	return (
		<>
			{paths.includes('files')
				? [...chain]?.reverse().map((item, index) => {
						const routeTo = '/files/' + item.id
						const isLast = index === [...chain].length - 1

						return isLast ? (
							<li key={index}>
								{getTitle(item.id) ? (
									getTitle(item.id)
								) : (
									<LoadingOutlined rev={undefined} />
								)}
							</li>
						) : (
							<li
								onClick={() => {
									navigate(routeTo)
								}}
								key={index}
							>
								{getTitle(item.id) ? (
									getTitle(item.id)
								) : (
									<LoadingOutlined rev={undefined} />
								)}
							</li>
						)
				  })
				: paths.map((path, index) => {
						const routeTo = paths.slice(0, index + 1).join('/')
						const isLast = index === paths.length - 1

						return isLast ? (
							<li key={index}>
								{getTitle(path) ? getTitle(path) : <LoadingOutlined rev={undefined} />}
							</li>
						) : (
							<li
								onClick={() => {
									if (EXCLUDEDPATHS.includes(routeTo)) {
										return
									}

									navigate(`/${routeTo}`)
								}}
								key={index}
							>
								{getTitle(path) ? getTitle(path) : <LoadingOutlined rev={undefined} />}
							</li>
						)
				  })}
		</>
	)
}
