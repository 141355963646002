import axios from 'axios';
import { backendApi } from '../api/backend-wrapper';

export const downloadFile = async (fileId: string) => {
    const re = await backendApi.apiFileInfoIdGet(fileId);

    axios
        .get(`/api/file/download?id=${fileId}`, {
            responseType: 'blob',
        })
        .then(response => {
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', re.data.file.name);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
};
