import { Outlet } from 'react-router-dom'
import Header from '../UI/Header/Header'
import Sider from '../UI/Sider/Sider'
import styles from './AppLayout.module.scss'
import React from 'react'

const AppLayout: React.FC = () => {
	return (
		<>
			<Header />
			<main className={styles.main}>
				<Sider />
				<div className={styles.content}>
					<Outlet />
				</div>
			</main>
		</>
	)
}

export default AppLayout
