import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';

export const useAuth = () => {
    const { token } = useSelector((state: RootState) => state.general);

    if (!token) {
        return false;
    }

    return true;
};
