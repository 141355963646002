import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import { fetchServerInfo, fetchUserInfo } from '../redux/general/general.actions'
import { RootState, useAppDispatch } from '../redux/store'
import './../assets/css/index.css'
import Dashboard from './Dashboard'
import AppLayout from './layout/AppLayout'
import { BpmEdit } from './common/BpmEdit/BpmEdit'
import { FormEdit } from './common/FormEdit/FormEdit'
import RequireAuth from './common/RequireAuth'
import Canvas from './excaliDraw/Canvas'
// import File from './files/File'
import { FilesLayout } from './files/FilesLayout'
import { Login } from './login/Login'
import { RoleForm } from './roles/RoleForm'
import { RolesLayout } from './roles/RolesLayout'
import { UserForm } from './users/UserForm'
import { UsersLayout } from './users/UsersLayout'

const App = () => {
	const generalState = useSelector((state: RootState) => state.general)
	const dispatch = useAppDispatch()
	const role: string[] = JSON.parse(localStorage.getItem('roles'))

	useEffect(() => {
		dispatch(fetchServerInfo())
		dispatch(fetchUserInfo(generalState.token))
	}, [generalState.initial])

	if (generalState.initial) {
		return <div>Loading...</div>
	}

	const checkRole = () => {
		if (!role) return null

		const admin = !!role.find(item => item === 'admin')

		if (admin) {
			return (
				<>
					<Route path={'settings/users'} element={<UsersLayout />} />
					<Route path={'settings/users/:id'} element={<UserForm />} />
					<Route path={'settings/roles'} element={<RolesLayout />} />
					<Route path={'settings/roles/:id'} element={<RoleForm />} />
				</>
			)
		} else {
			return null
		}
	}

	return (
		<Router>
			<Routes>
				<Route path='/login' element={<Login />} />
				<Route path='*' element={<Navigate to={'/'} />} />
				<Route element={<RequireAuth />}>
					<Route path='/canvas/:id' element={<Canvas />} />
					<Route path={'/bpmn/:id'} element={<BpmEdit />} />
					<Route path={'/form/:id'} element={<FormEdit />} />
					<Route path='/' element={<AppLayout />}>
						<Route index={true} element={<Dashboard />} />
						<Route path='files' element={<FilesLayout />}>
							<Route path={':id'} element={<FilesLayout />} />
							<Route path=':id/:mdId/md/view' element={<FilesLayout />} />
						</Route>
						{checkRole()}
					</Route>
					{/* <Route path='/file/:hash' element={<File />} /> */}
				</Route>
			</Routes>
		</Router>
	)
}

export default App
