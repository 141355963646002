import { Button, Space, message } from 'antd'
import axios from 'axios'
import lintModule from 'bpmn-js-bpmnlint'
import 'bpmn-js-bpmnlint/dist/assets/css/bpmn-js-bpmnlint.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css'
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css'
import 'bpmn-js/dist/assets/diagram-js.css'
import BpmnModeler from 'bpmn-js/lib/Modeler'
import React from 'react'
import { useParams } from 'react-router-dom'
import bpmnlintConfig from '../../../../.bpmnlintrc'
import { backendApi } from '../../../api/backend-wrapper'
import { FileDto } from '../../../generated/backend'

type BpmEditProps = {};

let emptyDiagram = `
    <?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_0ov40pg"  exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="12.0.0">
  <bpmn:process id="Process_0ht7j5v" isExecutable="false">
    <bpmn:startEvent id="StartEvent_13mmv4v" />
  </bpmn:process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_0ht7j5v">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_13mmv4v">
        <dc:Bounds x="156" y="82" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
    `;

export const BpmEdit: React.FC<BpmEditProps> = () => {
    const { id } = useParams();
    const [bpmnModeler, setBpmnModeler] = React.useState<BpmnModeler>();
    const [diagram, setDiagram] = React.useState(undefined);
    const [info, setInfo] = React.useState<FileDto>();

    const fetchDiagram = async () => {
        const response = await backendApi.apiFileDownloadGet(id);
        const res = await backendApi.apiFileInfoIdGet(id);

        setInfo(res.data.file);
        setDiagram(response.data);

        await initBpmn();
    };

    React.useEffect(() => {
        fetchDiagram();
    }, [id]);

    React.useEffect(() => {
        createDiagram();
    }, [diagram]);

    const initBpmn = async () => {
        const bpmn = new BpmnModeler({
            container: '#canvas',
            height: '100vh',
            keyboard: {
                bindTo: window,
            },
            linting: {
                bpmnlint: bpmnlintConfig,
            },
            additionalModules: [lintModule],
        });

        setBpmnModeler(bpmn);
    };

    const createDiagram = async () => {
        try {
            await bpmnModeler?.importXML(diagram ?? emptyDiagram);
        } catch (error) {
            console.error(error);
        }
    };

    const saveAsFile = async (bpmn: boolean) => {
        if (!bpmnModeler) return;

        const { xml } = await bpmnModeler.saveXML();
        const { svg } = await bpmnModeler.saveSVG();

        let data;

        if (bpmn) {
            data = new Blob([xml], { type: 'text/xml' });
        } else {
            data = new Blob([svg], { type: 'image/svg+xml' });
        }

        const href = URL.createObjectURL(data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'test');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        console.log({ xml, svg });
    };

    const save = async () => {
        if (!bpmnModeler) return;

        const { xml } = await bpmnModeler.saveXML();
        const data = new Blob([...xml]);
        const formData = new FormData();

        formData.append('diagram', data, info.name);

        try {
            await axios.put(`/api/file/update?fileId=${info.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Size': formData.get('diagram').size,
                },
            });

            message.success('Файл сохранен');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div id={'canvas'} style={{ position: 'relative' }}>
                <div className={'properties-panel'}></div>
                <Space
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        zIndex: 2,
                    }}
                >
                    <Button type={'primary'} onClick={save}>
                        Сохранить
                    </Button>
                </Space>
                <Space
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        left: '10px',
                        zIndex: 2,
                    }}
                >
                    <Button onClick={() => saveAsFile(true)}>Скачать BPMN</Button>
                    <Button onClick={() => saveAsFile(false)}>Скачать SVG</Button>
                </Space>
            </div>
        </div>
    );
};
