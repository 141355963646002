import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleDto } from '../../generated/backend';
import { fetchRoles } from '../../redux/roles/roles.actions';
import { RootState, useAppDispatch } from '../../redux/store';
import { Loader } from '../UI/Loader/Loader';
import { RoleAddModal } from './RoleAddModal';

type RolesLayoutProps = {};
export const RolesLayout: React.FC<RolesLayoutProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { roles, loading } = useSelector((state: RootState) => state.roles);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(fetchRoles());
    }, [id]);

    const columns: ColumnsType<RoleDto> = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Название',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/settings/roles/${record.id}`)}>
                    {text}
                </span>
            ),
        },
        {
            title: 'Описание',
            dataIndex: 'description',
            key: 'description',
        },
    ];

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Title level={2}>Список ролей</Title>
            <RoleAddModal />
            <Table columns={columns} dataSource={roles} />
        </div>
    );
};
