import React from 'react';
import { useNavigate } from 'react-router-dom';
import CheckCircle from '../../../assets/img/check-circle.svg';
import RbiLogoWhiteMin from '../../../assets/img/logo-white-min.svg';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import styles from './Header.module.css';

const Header: React.FC = () => {
    const navigate = useNavigate();

    return (
        <header className={styles.header}>
            <div className={styles.logo} onClick={() => navigate('/')}>
                <RbiLogoWhiteMin />
                <div className={styles.logoTitle}>FileServer</div>
            </div>
            <div className={styles.content}>
                <div className={styles.text}>
                    <CheckCircle />
                    Какая то заглушка
                </div>
                <ul className={styles.breadCrumbs}>
                    <Breadcrumbs />
                </ul>
            </div>
        </header>
    );
};
export default Header;
