// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sMh1hlztBqucbWwgF5Ze{display:grid;grid-template-columns:90px 1fr}.DfnBDRbaQlJHiYCMXHAh{padding:18px;overflow:hidden;height:calc(100vh - 72px)}", "",{"version":3,"sources":["webpack://./components/layout/AppLayout.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,8BAAA,CAEJ,sBACI,YAAA,CACA,eAAA,CACA,yBAAA","sourcesContent":[".main {\n    display: grid;\n    grid-template-columns: 90px 1fr;\n}\n.content {\n    padding: 18px;\n    overflow: hidden;\n    height: calc(100vh - 72px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "sMh1hlztBqucbWwgF5Ze",
	"content": "DfnBDRbaQlJHiYCMXHAh"
};
export default ___CSS_LOADER_EXPORT___;
