import { Button, Progress, Upload, UploadProps } from 'antd'
import { RcFile, UploadFile } from 'antd/es/upload'
import React from 'react'

type ChunkUploadProps = {
	uploadProps: UploadProps
	fileList: UploadProps<RcFile>['fileList']
}

const ChunkUpload: React.FC<ChunkUploadProps> = ({ uploadProps, fileList }) => {
	const [uploading, setUploading] = React.useState(false)

	React.useEffect(() => {
		if (fileList.length > 0) {
			setUploading(true)
		} else {
			setUploading(false)
		}
	}, [fileList])

	return (
		<>
			<Upload {...uploadProps}>
				<Button disabled={uploading} loading={uploading}>
					Загрузить
				</Button>
			</Upload>
			<section>
				{fileList.map((file: UploadFile<RcFile>) => {
					return (
						<React.Fragment key={file.uid}>
							<div>Загрузка файла {file.name}</div>
							<Progress
								format={percent => `${parseFloat(percent.toFixed(2))}%`}
								percent={file?.percent}
								status='active'
							/>
						</React.Fragment>
					)
				})}
			</section>
		</>
	)
}

export default ChunkUpload
