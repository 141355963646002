import { Excalidraw } from '@excalidraw/excalidraw';
import { ExcalidrawImperativeAPI, ExcalidrawInitialDataState } from '@excalidraw/excalidraw/types/types';
import { Button, message } from 'antd';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import { backendApi } from '../../api/backend-wrapper';
import { Loader } from '../UI/Loader/Loader';

type Props = {};

const Canvas: React.FC = (props: Props) => {
    const { id } = useParams();
    const [excalidrawAPI, setExcalidrawAPI] = React.useState<ExcalidrawImperativeAPI>(null);
    const [file, setFile] = React.useState<ExcalidrawInitialDataState>();

    React.useEffect(() => {
        backendApi.apiFileDownloadGet(id).then(res => {
            setFile(res.data);
        });
    }, []);

    const saveFile = async () => {
        const data = {
            version: 2,
            type: 'excalidraw',
            elements: excalidrawAPI.getSceneElements(),
            appState: { ...excalidrawAPI.getAppState(), collaborators: [] },
            files: excalidrawAPI.getFiles(),
        };

        const formData = new FormData();

        formData.append('diagram', new Blob([JSON.stringify(data)]));

        try {
            await axios.put(`/api/file/update?fileId=${id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Size': formData.get('diagram').size,
                },
            });

            message.success('Файл сохранен');
        } catch (e) {
            console.log(e);
        }
    };

    if (!file) {
        return <Loader />;
    }

    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <Excalidraw
                renderTopRightUI={() => {
                    return (
                        <Button block style={{ height: 36, borderRadius: 8 }} type='primary' onClick={saveFile}>
                            Сохранить
                        </Button>
                    );
                }}
                excalidrawAPI={api => setExcalidrawAPI(api)}
                initialData={{ elements: file?.elements, appState: file?.appState, scrollToContent: true }}
                langCode='ru-RU'
                isCollaborating={false}
            />
        </div>
    );
};

export default Canvas;
