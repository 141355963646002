import { createAsyncThunk } from '@reduxjs/toolkit'
import { roleApi, userApi } from '../../api/backend-wrapper'
import { UserDto } from '../../generated/backend'

export const fetchRoles = createAsyncThunk('roles/fetch-roles', async (_, { rejectWithValue }) => {
	try {
		const response = await roleApi.apiRoleGet()

		return response.data
	} catch (error) {
		return rejectWithValue(error.message)
	}
})

export const createRole = createAsyncThunk(
	'roles/create-role',
	async (name: string, { rejectWithValue }) => {
		try {
			const response = await roleApi.apiRoleCreatePost(name)

			return response.data
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

export const fetchRoleById = createAsyncThunk(
	'roles/fetch-role-id',
	async (roleId: number, { rejectWithValue }) => {
		try {
			const response = await roleApi.apiRoleIdGet(roleId)

			return response.data
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

export const attachUserToRole = createAsyncThunk(
	'roles/attach-user-to-role',
	async (
		{ userId, roleId, filteredUsers }: { userId: number; roleId: number; filteredUsers: UserDto[] },
		{ rejectWithValue }
	) => {
		try {
			await userApi.apiUserAddToRolePost(userId, roleId)

			return filteredUsers
		} catch (error) {
			return rejectWithValue(error.message)
		}
	}
)

// export const deleteRole = createAsyncThunk('roles/delete-role', async (roleId: number, { rejectWithValue }) => {
// 	try {
//         const response = await backendApi.(roleId);

//         return response.data;
//     } catch (error) {
//         return rejectWithValue(error.message);
//     }
// });
