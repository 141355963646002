import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Space, message } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../../../redux/store';
import { deleteFile } from '../../../../redux/files/files.actions';

type DeleteModalProps = {
    fileId: string;
    isPopup?: boolean;
};
export const DeleteModal: React.FC<DeleteModalProps> = ({ fileId, isPopup }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const onCancel = () => setShowDeleteModal(false);
    const onOpenModal = () => setShowDeleteModal(true);

    return (
        <>
            {isPopup ? (
                <Button danger={true} type={'text'} size={'small'} onClick={onOpenModal} style={{ width: '100%' }}>
                    Удалить
                </Button>
            ) : (
                <Button icon={<DeleteOutlined rev={undefined} />} type={'text'} onClick={onOpenModal} />
            )}
            <Modal
                centered={true}
                onCancel={onCancel}
                footer={false}
                title={'Вы действительно хотите удалить этот файл?'}
                open={showDeleteModal}
            >
                <Space>
                    <Button
                        danger={true}
                        onClick={() => {
                            dispatch(deleteFile(fileId)).then(() => {
                                message.success('Файл успешно удален');
                            });

                            onCancel();
                        }}
                    >
                        Да
                    </Button>
                    <Button onClick={onCancel}>Нет</Button>
                </Space>
            </Modal>
        </>
    );
};
