import { Avatar, Button, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchRoles } from '../../redux/roles/roles.actions';
import { RootState, useAppDispatch } from '../../redux/store';
import { attachRoleToUser, fetchUserById } from '../../redux/users/users.actions';
import { Loader } from '../UI/Loader/Loader';

type UserFormProps = {};

export const UserForm: React.FC<UserFormProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const selectedUser = useSelector((state: RootState) => state.users.selected);
    const { roles } = useSelector((state: RootState) => state.roles);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(fetchUserById(+id));
    }, [id]);

    const onSubmit = async () => {
        //Сохранение пользователя
    };

    if (selectedUser.loading) {
        return <Loader />;
    }

    return (
        <>
            <Space style={{ marginBottom: '12px' }}>
                <Button type={'primary'}>Сохранить</Button>
                <Button onClick={() => navigate('/settings/users')}>Назад</Button>
            </Space>
            <Row>
                <Col span={3}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}
                    >
                        <Avatar size={120} />
                    </div>
                </Col>
                <Col span={12}>
                    <Form labelCol={{ span: 3 }} labelAlign={'left'}>
                        <Form.Item label={'Логин'}>
                            <Input placeholder={'Имя'} value={selectedUser?.user?.login} disabled={true} />
                        </Form.Item>
                        <Form.Item label={'Пароль'}>
                            <Input
                                placeholder={'Пароль'}
                                value={''}
                                name={'password'}
                                // onChange={event => setData(setFieldValue(event.target.name, event.target.value, data))}
                            />
                        </Form.Item>
                        <Form.Item label={'Роль'}>
                            <Select
                                placeholder={'Роль'}
                                options={roles?.map(role => ({
                                    value: role.id,
                                    label: role.name,
                                }))}
                                mode={'multiple'}
                                value={selectedUser?.user?.roles.map(role => role.name)}
                                showSearch
                                filterOption={(input, option: { label: string; value: number }) =>
                                    (option?.label ?? '').includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '')
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onSelect={(value, option: { label: string; value: number }) => {
                                    const selectedRole = roles.find(item => item.id === option.value);

                                    const filteredRoles = roles.filter(item => item.id !== selectedRole.id);

                                    dispatch(
                                        attachRoleToUser({
                                            userId: selectedUser.user.id,
                                            roleId: selectedRole.id,
                                            filteredRoles,
                                        }),
                                    );
                                }}
                                onFocus={() => {
                                    if (roles.length === 0) {
                                        dispatch(fetchRoles());
                                    }
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
