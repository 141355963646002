
var cache = {};

/**
 * A resolver that caches rules and configuration as part of the bundle,
 * making them accessible in the browser.
 *
 * @param {Object} cache
 */
function Resolver() {}

Resolver.prototype.resolveRule = function(pkg, ruleName) {

  const rule = cache[pkg + '/' + ruleName];

  if (!rule) {
    throw new Error('cannot resolve rule <' + pkg + '/' + ruleName + '>');
  }

  return rule;
};

Resolver.prototype.resolveConfig = function(pkg, configName) {
  throw new Error(
    'cannot resolve config <' + configName + '> in <' + pkg +'>'
  );
};

var resolver = new Resolver();

var rules = {
  "conditional-flows": "error",
  "end-event-required": "error",
  "event-sub-process-typed-start-event": "error",
  "fake-join": "warn",
  "label-required": "error",
  "no-complex-gateway": "error",
  "no-disconnected": "error",
  "no-duplicate-sequence-flows": "error",
  "no-gateway-join-fork": "error",
  "no-implicit-split": "error",
  "no-inclusive-gateway": "error",
  "single-blank-start-event": "error",
  "single-event-definition": "error",
  "start-event-required": "error",
  "sub-process-blank-start-event": "error",
  "superfluous-gateway": "warning"
};

var config = {
  rules: rules
};

var bundle = {
  resolver: resolver,
  config: config
};

export { resolver, config };

export default bundle;



import rule_0 from 'bpmnlint/rules/conditional-flows';
cache['bpmnlint/conditional-flows'] = rule_0;

import rule_1 from 'bpmnlint/rules/end-event-required';
cache['bpmnlint/end-event-required'] = rule_1;

import rule_2 from 'bpmnlint/rules/event-sub-process-typed-start-event';
cache['bpmnlint/event-sub-process-typed-start-event'] = rule_2;

import rule_3 from 'bpmnlint/rules/fake-join';
cache['bpmnlint/fake-join'] = rule_3;

import rule_4 from 'bpmnlint/rules/label-required';
cache['bpmnlint/label-required'] = rule_4;

import rule_5 from 'bpmnlint/rules/no-complex-gateway';
cache['bpmnlint/no-complex-gateway'] = rule_5;

import rule_6 from 'bpmnlint/rules/no-disconnected';
cache['bpmnlint/no-disconnected'] = rule_6;

import rule_7 from 'bpmnlint/rules/no-duplicate-sequence-flows';
cache['bpmnlint/no-duplicate-sequence-flows'] = rule_7;

import rule_8 from 'bpmnlint/rules/no-gateway-join-fork';
cache['bpmnlint/no-gateway-join-fork'] = rule_8;

import rule_9 from 'bpmnlint/rules/no-implicit-split';
cache['bpmnlint/no-implicit-split'] = rule_9;

import rule_10 from 'bpmnlint/rules/no-inclusive-gateway';
cache['bpmnlint/no-inclusive-gateway'] = rule_10;

import rule_11 from 'bpmnlint/rules/single-blank-start-event';
cache['bpmnlint/single-blank-start-event'] = rule_11;

import rule_12 from 'bpmnlint/rules/single-event-definition';
cache['bpmnlint/single-event-definition'] = rule_12;

import rule_13 from 'bpmnlint/rules/start-event-required';
cache['bpmnlint/start-event-required'] = rule_13;

import rule_14 from 'bpmnlint/rules/sub-process-blank-start-event';
cache['bpmnlint/sub-process-blank-start-event'] = rule_14;

import rule_15 from 'bpmnlint/rules/superfluous-gateway';
cache['bpmnlint/superfluous-gateway'] = rule_15;