import { configureStore } from '@reduxjs/toolkit'
import generalReducer from './general/generalSlice'
import { useDispatch } from 'react-redux'
import cmsReducer from './cmsSlice'
import notificationsReducer from './notificationsSlice'
import filesReducer from './files/filesSlice'
import usersReducer from './users/usersSlice'
import rolesReducer from './roles/rolesSlice'

export const store = configureStore({
	reducer: {
		general: generalReducer,
		cms: cmsReducer,
		notifications: notificationsReducer,
		files: filesReducer,
		users: usersReducer,
		roles: rolesReducer,
	},
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
