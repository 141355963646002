import axios, { AxiosRequestConfig } from 'axios'
import { Chunks } from '../types/СhunkUpload.types'

export const startUpload = () =>
	axios.post('/api/file/upload/start').then(response => {
		if (response.status > 300) return

		return {
			key: response.data.key,
			uploadId: response.data.uploadId,
		}
	})

export const uploadChunk = async (chunk: Blob, config: AxiosRequestConfig) => {
	const formData = new FormData()
	formData.append('chunk', chunk)

	return await axios
		.post('/api/file/upload/chunk', formData, config)
		.then(response => response.data)
}

export const endUpload = async (
	config: AxiosRequestConfig,
	fileName: string,
	callback: React.Dispatch<React.SetStateAction<Chunks>>
) => {
	const uploadedChunks = JSON.parse(localStorage.getItem(fileName))

	return axios.post('/api/file/upload/end', uploadedChunks, config).then(response => {
		if (response.status > 300) return

		localStorage.removeItem(fileName)

		callback(prev => {
			delete prev[fileName]
			return prev
		})
		return response.data
	})
}
