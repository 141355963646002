import {
	CloseOutlined,
	DownloadOutlined,
	EditOutlined,
	EyeOutlined,
	FileOutlined,
	FolderOutlined,
} from '@ant-design/icons'
import { Button, Space, Table, UploadProps } from 'antd'
import { ColumnsType } from 'antd/es/table'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FileDtoFileTypeEnum } from '../../generated/backend'
import { RootState } from '../../redux/store'
import { downloadFile } from '../../utils/downloadFile'
import { Loader } from '../UI/Loader/Loader'
import { DeleteModal } from '../UI/Modals/DeleteModal/DeleteModal'
import { Popup } from '../UI/Popup/Popup'
import ChunkUpload from './ChunkUpload'
import { IFile } from '../../types/File.types'
import WarningModal from '../UI/Modals/WarningModal/WarningModal'

type FilesTableProps = {
	uploadProps: UploadProps
	fileList: UploadProps['fileList']
	showMdPreview: {
		show: boolean
		text: string
		id: string
	}
	downloadMdFile: (mdId: string) => Promise<void>
	onCloseMdPreview: () => void
}

interface IPopup {
	x: number
	y: number
	visible: boolean
	id: string
}

const FilesTable: React.FC<FilesTableProps> = ({
	uploadProps,
	showMdPreview,
	fileList,
	downloadMdFile,
	onCloseMdPreview,
}) => {
	const navigate = useNavigate()
	const { id } = useParams()
	const { files, loading } = useSelector((state: RootState) => state.files)

	const [popupProps, setPopupProps] = React.useState<IPopup>({
		x: 0,
		y: 0,
		visible: false,
		id: '',
	})

	const list = files.map(item => {
		return {
			...item,
			title: (
				<>
					<Space>
						{item.isFolder ? <FolderOutlined rev={undefined} /> : <FileOutlined rev={undefined} />}
						{item.name}
					</Space>
				</>
			),
			key: item.id,
		}
	})

	const columns: ColumnsType<IFile> = [
		{
			title: 'Name',
			dataIndex: 'title',
			key: 'name',
			width: '95%',
		},
		{
			title: '',
			dataIndex: 'action',
			key: 'action',
			render: (_, record) => (
				<Space>
					<Button
						type={'text'}
						icon={<DownloadOutlined rev={undefined} />}
						download
						onClick={() => downloadFile(record.id)}
					/>
					{record.name.includes('.md') ? (
						<Button
							type={'text'}
							icon={
								showMdPreview.show && showMdPreview.id === record.id ? (
									<CloseOutlined rev={undefined} />
								) : (
									<EyeOutlined rev={undefined} />
								)
							}
							onClick={() => {
								if (showMdPreview.show) {
									onCloseMdPreview()
									navigate(`/files/${id}`)

									return
								}
								downloadMdFile(record.id)
							}}
						/>
					) : (
						<Button //todo only if file
							type={'text'}
							icon={<EditOutlined rev={undefined} />}
							onClick={() => {
								if (record.fileType === FileDtoFileTypeEnum.Docx) {
									openInWord(record.id)
									return
								}

								openTab(record.id, record.name)
							}}
						/>
					)}
					<DeleteModal fileId={record.id} />
				</Space>
			),
		},
	]

	const openInWord = async (id: string) => {
		const host = window.location.protocol + '//' + window.location.host
		// const host = 'https://test.office.rbiconcept.ru';     //todo env
		window.open(`ms-word:ofe|u|${host}/webdav/${id}.docx`)
	}

	const openTab = (id: string, name: string) => {
		if (name.includes('.bpmn')) {
			window.open(`/bpmn/${id}`, '_blank')
			return
		}
		if (name.includes('.json')) {
			window.open(`/form/${id}`, '_blank')
			return
		}
		if (name.includes('.excalidraw')) {
			window.open(`/canvas/${id}`, '_blank')
			return
		}
	}

	if (loading) {
		return <Loader />
	}

	return (
		<>
			<Table
				columns={columns}
				dataSource={list}
				size={'small'}
				footer={() => {
					return (
						<Space direction={'vertical'}>
							<ChunkUpload uploadProps={uploadProps} fileList={fileList} />
							<div>Или можно перенести файлы в таблицу.</div>
						</Space>
					)
				}}
				onRow={(record, rowIndex) => {
					return {
						onDoubleClick: event => {
							event.preventDefault()

							if (!record.isFolder) {
								return
							}

							navigate(`/files/${record.id}`)
						},
						onContextMenu: event => {
							event.preventDefault()
							document.addEventListener(`click`, function onClickOutside() {
								setPopupProps({
									x: 0,
									y: 0,
									visible: false,
									id: record.id,
								})
								document.removeEventListener(`click`, onClickOutside)
							})

							setPopupProps({
								x: event.clientX,
								y: event.clientY,
								visible: true,
								id: record.id,
							})
						},
					}
				}}
				pagination={false}
			/>
			<Popup {...popupProps} />
		</>
	)
}

export default FilesTable
