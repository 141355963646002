import { FileAddOutlined, FolderOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, message } from 'antd';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../../redux/store';
import { createFile, createFolder } from '../../../../redux/files/files.actions';
import { rootDir } from '../../../files/FilesLayout';

type FilesModalsProps = {};

export const FilesModals: React.FC<FilesModalsProps> = ({}) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [showModal, setShowModal] = React.useState<{ show: boolean; isFolder: boolean }>({
        show: false,
        isFolder: false,
    });
    const dispatch = useAppDispatch();

    const onOpenFolderModal = () => setShowModal({ show: true, isFolder: true });
    const onOpenFileModal = () => setShowModal({ show: true, isFolder: false });

    const onCancel = () => {
        setShowModal({ show: false, isFolder: false });
        form.resetFields();
    };

    return (
        <>
            <Space>
                <Button icon={<FolderOutlined rev={undefined} />} onClick={onOpenFolderModal}>
                    Создать папку
                </Button>
                <Button icon={<FileAddOutlined rev={undefined} />} onClick={onOpenFileModal}>
                    Создать файл
                </Button>
            </Space>
            <Modal
                centered
                footer={false}
                title={showModal.isFolder ? 'Создание папки' : 'Создание файла'}
                open={showModal.show}
                onCancel={onCancel}
            >
                <Form
                    onFinish={values => {
                        showModal.isFolder
                            ? dispatch(createFolder({ fileName: values.name, parentId: id ?? rootDir })).then(() =>
                                  message.success('Папка создана!'),
                              )
                            : dispatch(
                                  createFile({
                                      fileName: values.name,
                                      extension: values.type,
                                      parentId: id ?? rootDir,
                                  }),
                              ).then(() => message.success('Файл создан!'));

                        onCancel();
                    }}
                    form={form}
                    layout='vertical'
                >
                    {showModal.isFolder ? null : (
                        <Form.Item name={'type'} label={'Расширение файла'}>
                            <Select
                                size='large'
                                options={[{ value: 'DOCX', label: 'DOCX' }]}
                                placeholder={'Расширение файла'}
                            />
                        </Form.Item>
                    )}

                    <Form.Item name={'name'} label={showModal.isFolder ? 'Название папки' : 'Название файла'}>
                        <Input size='large' />
                    </Form.Item>

                    <Form.Item>
                        <Space>
                            <Button htmlType='submit' type={'primary'}>
                                Создать
                            </Button>
                            <Button htmlType='button' onClick={onCancel}>
                                Закрыть
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};
