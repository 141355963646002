import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IFile } from '../../types/File.types'
import { createFile, createFolder, deleteFile, fetchFiles, uploadFile } from './files.actions'

interface IFileState {
	chain: IFile[]
	files: IFile[]
	loading: boolean
}

const initialState: IFileState = {
	chain: [],
	files: [],
	loading: false,
}

export const filesSlice = createSlice({
	name: 'files',
	initialState,
	reducers: {
		setFile: (state, action: PayloadAction<IFile>) => {
			state.files = state.files.filter(file => file.name !== action.payload.name)
			state.files.push(action.payload)
		},
	},
	extraReducers: builder => {
		builder
			.addCase(fetchFiles.pending, state => {
				state.loading = true
			})
			.addCase(fetchFiles.fulfilled, (state, action) => {
				state.loading = false
				state.files = action.payload.list
				state.chain = action.payload.chain
			})
			.addCase(fetchFiles.rejected, state => {
				state.loading = false
			})
			.addCase(deleteFile.fulfilled, (state, action) => {
				state.files = state.files.filter(file => file.id !== action.payload)
			})
			.addCase(createFile.fulfilled, (state, action) => {
				state.files.push(action.payload)
			})
			.addCase(createFolder.fulfilled, (state, action) => {
				state.files.push(action.payload)
			})
			.addCase(uploadFile.fulfilled, (state, action) => {
				state.files.push(action.payload)
			})
	},
})

export const { setFile } = filesSlice.actions

export default filesSlice.reducer
