import React from 'react'
import {
	DefaultApiFactory,
	FileApiFactory,
	RoleApiFactory,
	ShareApiFactory,
	UserApiFactory,
} from '../generated/backend'
import axios from 'axios'
import { message } from 'antd'

export const backendApi = DefaultApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin, //todo is this really needed?
	// accessToken: ''
})

export const fileApi = FileApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const roleApi = RoleApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const shareApi = ShareApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

export const userApi = UserApiFactory({
	isJsonMime(mime: string): boolean {
		return false
	},
	basePath: window.location.origin,
})

axios.interceptors.request.use(
	config => {
		config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
		config.headers.tenantId = 'default' //todo env

		return config
	},
	error => Promise.reject(error)
)

axios.interceptors.response.use(
	function (response) {
		//Dosomethingwithresponsedata
		return response
	},
	function (error) {
		//401
		if (error.response.status === 401 && window.location.pathname !== '/login') {
			//redirect to auth page
			console.log(error)
			// location.replace('/login');
		}
		//todo all 500 check
		if (error.response.status >= 500) {
			console.log(error)
			message.error(<>{error.message}</>, 4)
		}

		return Promise.reject(error)
	}
)
