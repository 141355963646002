import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { message } from 'antd'
import { fetchServerInfo, fetchUserInfo, login } from './general.actions'

export interface GeneralState {
    value: number;
    initial: boolean;
    token: string;
    username: string;
    password: string;
    roles: string[];
    name: string;
    externalUrl: string;
    version: string;
}

const initialState: GeneralState = {
    value: 0,
    username: 'admin',
    password: 'admin',
    name: '',
    initial: true,
    token: localStorage.getItem('token'),
    roles: JSON.parse(localStorage.getItem('role')),
    externalUrl: '',
    version: '',
};

export const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        changeUsername: (state, action: PayloadAction<string>) => {
            state.username = action.payload;
        },
        changePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
        },
        logout: state => {
            state.token = undefined;
            localStorage.setItem('token', undefined);
            localStorage.setItem('roles', JSON.stringify([]));
        },
    },
    extraReducers: builder => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder
            .addCase(fetchUserInfo.fulfilled, (state, action) => {
                // message.info(`Добро пожаловать, ${action.payload.username}`);
                // state.name = action.payload.username; //todo
                state.initial = false;
            })
            .addCase(fetchUserInfo.rejected, (state, action) => {
                // Add user to the state array
                //state.entities.push(action.payload)
                state.initial = false;
                state.token = undefined;
                localStorage.setItem('token', '');
            })
            .addCase(login.fulfilled, (state, action) => {
                // Add user to the state array
                state.token = action.payload.token;
                // state.name = action.payload.username
                state.roles = action.payload.roles;
                //store token
                localStorage.setItem('token', state.token);

                //store Role
                localStorage.setItem('roles', JSON.stringify(action.payload.roles));
            })
            .addCase(login.rejected, (state, action) => {
                // Add user to the state array
                message.error(<>{action.payload}</>);
            })
            .addCase(fetchServerInfo.fulfilled, (state, action) => {
                state.version = action.payload.version;
                state.externalUrl = action.payload.externalUrl;
            })
            .addCase(fetchServerInfo.rejected, (state, action) => {
                message.error(<>{action.payload}</>);
            });
        //todo add cases (errors etc)
    },
});

// Action creators are generated for each case reducer function
export const { changeUsername, changePassword, logout } = generalSlice.actions;

export default generalSlice.reducer;
