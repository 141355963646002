import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from '@reduxjs/toolkit'
import {officeApi} from "../api/office-wrapper";
import axios from "axios";
import {userInfo} from "os";

type Message = {
    type: 'error'|'success',
    text: string
}

export interface NotificationsState {
    messages: Message[],
}

const initialState: NotificationsState = {
    messages: []
}



export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addMessage: (state, action: PayloadAction<Message>) => {
            state.messages = [...state.messages, action.payload]
        },
        removeMessage: (state, action: PayloadAction<number>) => {
            state.messages = state.messages.filter((item, index) => index !== action.payload)
        },
    },
    // extraReducers: (builder) => {
    //
    //     // Add reducers for additional action types here, and handle loading state as needed
    //     builder
    //         .addCase(fetchUserInfo.fulfilled, (state, action) => {
    //             state.name = action.payload.username //todo
    //             state.initial = false
    //         })
    //         .addCase(fetchUserInfo.rejected, (state, action) => {
    //             // Add user to the state array
    //             //state.entities.push(action.payload)
    //             state.initial = false
    //             state.token = undefined
    //         })
    //         .addCase(login.fulfilled, (state, action) => {
    //             // Add user to the state array
    //             state.token = action.payload.access_token
    //             // state.name = action.payload.username
    //
    //             //store token
    //             localStorage.setItem("token", state.token)
    //
    //             //
    //
    //         })
    //         .addCase(login.rejected, (state, action) => {
    //             // Add user to the state array
    //             console.log(11);
    //         })
    //     //todo add cases (errors etc)
    // },
})

// Action creators are generated for each case reducer function
export const { addMessage, removeMessage } = notificationsSlice.actions

export default notificationsSlice.reducer