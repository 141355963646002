import { createSlice } from '@reduxjs/toolkit';
import { RoleDto } from '../../generated/backend';
import { createRole, fetchRoleById, fetchRoles } from './roles.actions';

interface IRolesState {
    roles: RoleDto[];
    loading: boolean;
    selected: {
        role: RoleDto;
        loading: boolean;
    };
}

const initialState: IRolesState = {
    roles: [],
    loading: false,
    selected: {
        role: null,
        loading: false,
    },
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRoles.pending, state => {
                state.loading = true;
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.loading = false;
                state.roles = action.payload;
            })
            .addCase(fetchRoles.rejected, state => {
                state.loading = false;
            })
            .addCase(createRole.fulfilled, (state, action) => {
                state.roles.push(action.payload);
            })
            .addCase(fetchRoleById.pending, state => {
                state.selected.loading = true;
            })
            .addCase(fetchRoleById.fulfilled, (state, action) => {
                state.selected.role = action.payload;
                state.selected.loading = false;
            })
            .addCase(fetchRoleById.rejected, state => {
                state.selected.loading = false;
            });
        // .addCase(deleteRole.fulfilled, (state, action) => {
        //     state.roles = state.roles.filter(role => role.id !== action.payload);
        // });
    },
});

export const {} = rolesSlice.actions;

export default rolesSlice.reducer;
