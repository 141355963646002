import { createSlice } from '@reduxjs/toolkit';
import { UserDto } from '../../generated/backend';
import { attachRoleToUser, createUser, deleteUser, fetchUserById, fetchUsers } from './users.actions';

interface IUserState {
    users: UserDto[];
    loading: boolean;
    selected: {
        user: UserDto | null;
        loading: boolean;
    };
}

const initialState: IUserState = {
    users: [],
    loading: false,
    selected: {
        user: null,
        loading: false,
    },
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchUsers.pending, state => {
                state.loading = true;
            })
            .addCase(fetchUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(fetchUsers.rejected, state => {
                state.loading = false;
            })
            .addCase(createUser.fulfilled, (state, action) => {
                state.users.push(action.payload);
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.users = state.users.filter(user => user.id !== action.payload);
            })
            .addCase(fetchUserById.pending, state => {
                state.selected.loading = true;
            })
            .addCase(fetchUserById.fulfilled, (state, action) => {
                state.selected.loading = false;
                state.selected.user = action.payload;
            })
            .addCase(fetchUserById.rejected, state => {
                state.selected.loading = false;
            })
            .addCase(attachRoleToUser.fulfilled, (state, action) => {
                state.selected.user.roles = action.payload;
            });
    },
});

export const {} = usersSlice.actions;

export default usersSlice.reducer;
