import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import React from 'react'
import styles from './Loader.module.scss'

type LoaderProps = {
    general?: boolean;
};
export const Loader: React.FC<LoaderProps> = ({ general }) => {
    return (
        <div className={general ? styles.generalContainer : styles.container}>
            <Spin size={'large'} indicator={<LoadingOutlined style={{ fontSize: 50 }} spin rev={undefined} />} />
        </div>
    );
};
