import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RbiBlackLogo from '../../assets/img/logo-black.svg'
import { login } from '../../redux/general/general.actions'
import { changePassword, changeUsername } from '../../redux/general/generalSlice'
import { RootState, useAppDispatch } from '../../redux/store'
import styles from './Login.module.css'

export function Login() {
	const generalState = useSelector((state: RootState) => state.general)
	const dispatch = useAppDispatch()

	const navigate = useNavigate()

	React.useEffect(() => {
		if (generalState.token) {
			navigate(-1)
		}
	}, [generalState.token])
	return (
		<div className={styles.container}>
			<Form className={styles.loginForm}>
				<div className={styles.logo}>
					<RbiBlackLogo />
				</div>
				<Form.Item rules={[{ required: true, message: 'Введите имя пользователя' }]}>
					<Input
						prefix={<UserOutlined rev={undefined} />}
						placeholder='Имя пользовотеля...'
						value={generalState.username}
						onChange={event => dispatch(changeUsername(event.target.value))}
					/>
				</Form.Item>
				<Form.Item>
					<Input.Password
						prefix={<LockOutlined rev={undefined} />}
						placeholder={'Пароль...'}
						value={generalState.password}
						onChange={event => dispatch(changePassword(event.target.value))}
					/>
				</Form.Item>
				<Form.Item>
					<Checkbox>Запомнить</Checkbox>
					<a href={''} className={styles.forgot}>
						Забыли пароль?
					</a>
				</Form.Item>
				<Form.Item>
					<Button
						htmlType={'submit'}
						type={'primary'}
						className={styles.button}
						onClick={event => {
							event.preventDefault()
							dispatch(
								login({
									username: generalState.username,
									password: generalState.password,
								})
							)
						}}
					>
						Войти
					</Button>
					Или <a href=''>Зарегистрироваться!</a>
				</Form.Item>
			</Form>
		</div>
	)
}
