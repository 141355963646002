import { FormEditor } from '@bpmn-io/form-js'
import '@bpmn-io/form-js/dist/assets/form-js-base.css'
import '@bpmn-io/form-js/dist/assets/form-js-editor-base.css'
import '@bpmn-io/form-js/dist/assets/form-js-editor.css'
import '@bpmn-io/form-js/dist/assets/form-js.css'
import { Button, Space, message } from 'antd'
import axios from 'axios'
import React from 'react'
import { useParams } from 'react-router-dom'
import { backendApi } from '../../../api/backend-wrapper'
import { FileDto } from '../../../generated/backend'

type Props = {};

const schema = {
    components: [],
    type: 'default',
};
export const FormEdit: React.FC = (props: Props) => {
    const { id } = useParams();
    const [formModeler, setFormModeler] = React.useState<FormEditor>();
    const [info, setInfo] = React.useState<FileDto>();
    const [form, setForm] = React.useState();

    const fetchForm = async () => {
        const response = await backendApi.apiFileDownloadGet(id);
        const res = await backendApi.apiFileInfoIdGet(id);

        setInfo(res.data.file);
        setForm(response.data);

        await initForm();
    };

    React.useEffect(() => {
        fetchForm();
    }, [id]);

    React.useEffect(() => {
        createForm();
    }, [form]);

    const initForm = async () => {
        const formBuilder = new FormEditor({
            container: '#form-editor',
        });

        setFormModeler(formBuilder);
    };

    const createForm = async () => {
        try {
            await formModeler?.importSchema(form ?? schema);
        } catch (error) {
            console.error(error);
        }
    };

    const save = async () => {
        const result = await formModeler.saveSchema();

        const data = new Blob([...JSON.stringify(result)]);

        const formData = new FormData();

        formData.append('diagram', data, info.name);

        console.log(formData.get('diagram').size);

        try {
            await axios.put(`/api/file/update?fileId=${info.id}`, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Size': formData.get('diagram').size,
                },
            });

            message.success('Файл сохранен');
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div>
            <div id={'form-editor'} style={{ height: '95vh' }}></div>
            <Space style={{ padding: '8px 0 0 12px' }}>
                <Button type={'primary'} onClick={save}>
                    Сохранить
                </Button>
            </Space>
        </div>
    );
};
