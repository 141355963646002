import { Button, Modal, Space, Typography } from 'antd'
import React from 'react'

type WarningModalProps = {
	title: string
	onCancel: () => void
	open: boolean
	onContinue: () => void
}

const WarningModal: React.FC<WarningModalProps> = ({ title, open, onCancel, onContinue }) => {
	return (
		<Modal open={open} centered width={400} destroyOnClose footer={false} onCancel={onCancel}>
			<Space align='center' direction='vertical'>
				<Typography.Title style={{ textAlign: 'center' }} level={3}>
					{title}
				</Typography.Title>
				<Space>
					<Button onClick={onContinue} size='large' type='primary' danger>
						Продолжить
					</Button>
					<Button onClick={onCancel} size='large'>
						Отмена
					</Button>
				</Space>
			</Space>
		</Modal>
	)
}

export default WarningModal
