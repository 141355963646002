import { UploadProps } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import React from 'react';

type UploadDraggerProps = {
    uploadProps: UploadProps;
    isDragging: boolean;
};

const UploadDragger: React.FC<UploadDraggerProps> = ({ uploadProps, isDragging }) => {
    return (
        <div className={'upload-container'} hidden={!isDragging} style={{ zIndex: isDragging ? 1000 : -100 }}>
            <Dragger
                {...uploadProps}
                type={'drag'}
                style={{
                    border: '2px dashed #1677ff',
                    fontSize: 32,
                    opacity: 1,
                    width: '500px',
                }}
            >
                Drop Files Here
            </Dragger>
        </div>
    );
};

export default UploadDragger;
