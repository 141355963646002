import React from 'react';
import { Provider } from 'react-redux';
import App from './components/App';
import { createRoot } from 'react-dom/client';
import { store } from './redux/store';
import { ConfigProvider } from 'antd';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 3,
                },
            }}
        >
            <App />
        </ConfigProvider>
    </Provider>,
);
