import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Navigate, Outlet } from 'react-router-dom';

const RequireAuth = () => {
    const isAuth = useAuth();

    return isAuth ? <Outlet /> : <Navigate to='/login' />;
};

export default RequireAuth;
