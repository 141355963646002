import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
import React from 'react';
import { useAppDispatch } from '../../redux/store';
import { deleteUser } from '../../redux/users/users.actions';

type UserDeleteModalProps = {
    userId: number;
};

export const UserDeleteModal: React.FC<UserDeleteModalProps> = ({ userId }) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();

    const prepareToDelete = () => {
        setShowDeleteModal(true);
    };

    const onCloseDeleteModal = () => {
        setShowDeleteModal(false);
    };

    return (
        <>
            <Button type={'text'} icon={<DeleteOutlined rev={undefined} onClick={() => prepareToDelete()} />} />
            <Modal
                centered={true}
                title={'Вы действительно хотите удалить пользователя?'}
                open={showDeleteModal}
                onCancel={onCloseDeleteModal}
                footer={false}
            >
                <Space>
                    <Button
                        danger={true}
                        onClick={() => {
                            dispatch(deleteUser(userId));
                            onCloseDeleteModal();
                        }}
                    >
                        Удалить
                    </Button>
                    <Button onClick={onCloseDeleteModal}>Отмена</Button>
                </Space>
            </Modal>
        </>
    );
};
