import { Button, Col, Form, Input, Row, Select, Space } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { attachUserToRole, fetchRoleById } from '../../redux/roles/roles.actions';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchUsers } from '../../redux/users/users.actions';
import { Loader } from '../UI/Loader/Loader';

type RoleFormProps = {};
export const RoleForm: React.FC<RoleFormProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const selectedRole = useSelector((state: RootState) => state.roles.selected);
    const { users } = useSelector((state: RootState) => state.users);
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(fetchRoleById(+id));
    }, [id]);

    const onSubmit = async () => {
        //Сохранение роли
    };

    if (selectedRole.loading) {
        return <Loader />;
    }

    return (
        <>
            <Space style={{ marginBottom: '12px' }}>
                <Button type={'primary'}>Сохранить</Button>
                <Button onClick={() => navigate('/settings/roles')}>Назад</Button>
            </Space>
            <Row>
                <Col span={8}>
                    <Form labelAlign={'left'} labelCol={{ span: 6 }}>
                        <Form.Item label={'Название'}>
                            <Input
                                placeholder={'Название'}
                                name={'name'}
                                value={selectedRole?.role?.name}
                                // onChange={event => setData(setFieldValue(event.target.name, event.target.value, data))}
                            />
                        </Form.Item>
                        <Form.Item label={'Описание'}>
                            <Input
                                placeholder={'Описание'}
                                name={'description'}
                                value={selectedRole?.role?.description}
                                // onChange={event => setData(setFieldValue(event.target.name, event.target.value, data))}
                            />
                        </Form.Item>
                        <Form.Item label={'Пользователи'}>
                            <Select
                                placeholder={'Пользователи'}
                                options={users.map(user => {
                                    const role = !!user.roles?.find(item => item.name === selectedRole.role.name);

                                    if (role) return;

                                    return {
                                        value: user.id,
                                        label: user.login,
                                    };
                                })}
                                mode={'multiple'}
                                value={selectedRole.role?.users.map(role => role.login)}
                                showSearch
                                filterOption={(input, option: { label: string; value: number }) =>
                                    (option?.label ?? '').includes(input)
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label ?? '')
                                        .toLowerCase()
                                        .localeCompare((optionB?.label ?? '').toLowerCase())
                                }
                                onSelect={(value, option: { label: string; value: number }) => {
                                    const selectedUser = users.find(item => item.id === option.value);

                                    const filteredUsers = users.filter(item => item.id !== selectedUser.id);

                                    dispatch(
                                        attachUserToRole({
                                            userId: selectedUser.id,
                                            roleId: selectedRole.role.id,
                                            filteredUsers,
                                        }),
                                    );
                                }}
                                onFocus={() => {
                                    if (users.length === 0) {
                                        dispatch(fetchUsers());
                                    }
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
