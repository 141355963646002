import { Avatar, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Title from 'antd/es/typography/Title';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { UserDto } from '../../generated/backend';
import { RootState, useAppDispatch } from '../../redux/store';
import { fetchUsers } from '../../redux/users/users.actions';
import { Loader } from '../UI/Loader/Loader';
import { UserAddModal } from './UserAddModal';
import { UserDeleteModal } from './UserDeleteModal';

type UsersProps = {};

export const UsersLayout: FC<UsersProps> = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const { users, loading } = useSelector((state: RootState) => state.users);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchUsers());
    }, [id]);

    const columns: ColumnsType<UserDto> = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'key',
        },
        {
            title: 'Имя',
            dataIndex: 'login',
            key: 'name',
            render: (text, record) => (
                <Space onClick={() => navigate(`/settings/users/${record.id}`)} style={{ cursor: 'pointer' }}>
                    <Avatar />
                    {text}
                </Space>
            ),
        },
        {
            title: 'Роль',
            dataIndex: 'role',
            key: 'role',
        },
        {
            title: '',
            key: 'action',
            render: (_, record) => <UserDeleteModal userId={record.id} />,
        },
    ];

    const createRole = async () => {};

    if (loading) {
        return <Loader />;
    }

    return (
        <div style={{ padding: '0 12px' }}>
            <Title level={2}>Список пользователей</Title>
            <UserAddModal />
            <Table dataSource={users.map(user => ({ ...user, key: user.id }))} columns={columns} size={'small'} />
        </div>
    );
};
